import { AvatarBreadcrumbInformationBoxTemplate } from '@netfront/ui-library';

import { TeamsTable } from '../Tables';

import { PageLayout } from '../../../PageLayout';

const PartnerManagementTeamsPage = () => {
  return (
    <div className="c-partner-management-teams-page">
      <PageLayout meta={{ description: 'Partner teams' }} size={'small'} title="Partner management teams" hasPrivateLayout>
        <AvatarBreadcrumbInformationBoxTemplate breadcrumbItems={[]} message="Manage and invite users to your project" title="Teams" />
        <TeamsTable />
      </PageLayout>
    </div>
  );
};

export { PartnerManagementTeamsPage };
