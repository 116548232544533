import { useEffect } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { Icons } from '@netfront/ui-library';
import { CachingEntitiesProvider } from 'context';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import { PageView } from '../components';

import '@netfront/ekardo-content-library/dist/index.css';
import '@netfront/ui-library/dist/index.css';

import '../styles/components.css';
import '../styles/globals.css';
import '../styles/theme.css';

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const { getUser } = useUser();
  const loggedUser = getUser();

  useEffect(() => {
    if (!loggedUser) {
      return;
    }

    const handleRouteChange = (url: string) => {
      PageView(url, String(loggedUser.id));
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return (
    <CachingEntitiesProvider>
      <div id="root">
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            duration: 3000,
          }}
        />
        <Icons />
        <Component {...pageProps} />
      </div>
    </CachingEntitiesProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
