import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { ILineChartProps } from './LineChart.interfaces';

const LineChart = ({ datasets, label, labels, legends }: ILineChartProps) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const data = {
    labels,
    datasets,
  };

  return (
    <div className="linechart-container">
      <div className="linechart-legend-container">
        <h4>{label}</h4>
        <ul className="linechart-legend-ul">
          {legends.map((r) => (
            <li key={r.label}>
              <div className="linechart-dot-legend" style={{ backgroundColor: r.color }}></div>
              <p>{r.label}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="linechart-chart">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};
export { LineChart };
