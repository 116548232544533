import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_THEME_MUTATION } from './useUpdateTheme.graphql';
import {
  IUpdateThemeMutationGraphQLResponse,
  IUpdateThemeMutationVariables,
  IHandleUpdateThemeParams,
  IUseUpdateThemeOptions,
  IUseUpdateTheme,
} from './useUpdateTheme.interfaces';

const useUpdateTheme = (options?: IUseUpdateThemeOptions): IUseUpdateTheme => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateThemeOptions);

  const [executeUpdateTheme, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateThemeMutationGraphQLResponse,
    IUpdateThemeMutationVariables
  >({
    mutation: mutation ?? UPDATE_THEME_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { updateTheme: isCompleted },
        } = data;

        onCompleted({
          result: isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateTheme = ({ input }: IHandleUpdateThemeParams) => {
    void executeUpdateTheme({
      variables: {
        input,
      },
    });
  };

  return {
    handleUpdateTheme,
    isLoading,
  };
};

export { useUpdateTheme };
