import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { InformationBox, Pagination, Spinner, SearchFilter, Spacing, Table, Toolbar } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useToast, useProtectedRoute, useGetReferralProjects, useGetProduct } from 'hooks';
import { IDBReferralProject } from 'interfaces';
import last from 'lodash.last';

import { REFERRALS_TABLE_COLUMNS } from './ReferralsTable.constants';
import { getReferralsTableData } from './ReferralsTable.helpers';
import { IReferralsTableData } from './ReferralsTable.interfaces';

const ReferralsTable = () => {
  const { handleToastError } = useToast();

  const { partner } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const { getProduct } = useGetProduct();
  const { isAuthenticated } = useProtectedRoute();

  const [allProjectReferrals, setAllProjectReferrals] = useState<IDBReferralProject[]>([]);
  const [filteredReferrals, setFilteredReferrals] = useState<IDBReferralProject[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [referralsTableData, setReferralsTableData] = useState<IReferralsTableData[]>([]);
  const [totalProjectReferrals, setTotalProjectReferrals] = useState<number>(0);

  const token = getAccessTokenCookie();

  const {
    handleGetReferralProjects,
    handleFetchMoreReferralProjects,
    isLoading: isGetReferralProjectsLoading,
  } = useGetReferralProjects({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ projectReferralsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const projectReferrals = edges.map(({ node }) => node);

      setFilteredReferrals(projectReferrals);
      setAllProjectReferrals(projectReferrals);
      setIsLoadMoreDisabled(projectReferrals.length >= totalCount || totalCount <= pageSize);
      setTotalProjectReferrals(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');

    setFilteredReferrals(allProjectReferrals);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);

    setFilteredReferrals(allProjectReferrals.filter(({ name }) => name?.toLowerCase().includes(value.toLowerCase())));
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    if (!partner) return;
    const { id } = partner;

    void handleFetchMoreReferralProjects({
      after: lastCursor,
      first: pageSize,
      partnerId: id,
      product: getProduct(),
    });
  };

  useEffect(() => {
    setReferralsTableData(
      getReferralsTableData({
        referrals: filteredReferrals,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReferrals]);

  useEffect(() => {
    if (!isAuthenticated || !partner) {
      return;
    }

    handleGetReferralProjects({
      partnerId: partner.id,
      product: getProduct(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pageSize, partner]);

  return (
    <div className="c-referrals-table">
      <Spacing>
        <Toolbar
          childrenStart={
            <SearchFilter
              id="referrals-filter"
              isDisabled={false}
              labelText="referrals filter"
              name="searchReferrals"
              placeholder="Search referrals"
              type="text"
              value={filter}
              isLabelHidden
              onChange={handleFilterChange}
              onClear={handleFilterClear}
              onSearch={handleFilterSearch}
            />
          }
        />
      </Spacing>

      {isGetReferralProjectsLoading ? (
        <Spinner isLoading={isGetReferralProjectsLoading} />
      ) : referralsTableData.length > 0 ? (
        <>
          <Spacing>
            <Table additionalClassNames="c-referrals-table" columns={REFERRALS_TABLE_COLUMNS} data={referralsTableData} />
          </Spacing>

          <Pagination
            isLoadMoreDisabled={isLoadMoreDisabled}
            pageSize={pageSize}
            totalItems={totalProjectReferrals}
            onPageChange={async () => {
              if (!partner) return;
              const { id } = partner;
              await handleFetchMoreReferralProjects({
                after: lastCursor,
                first: pageSize,
                partnerId: id,
                product: getProduct(),
              });
            }}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <InformationBox additionalClassNames="c-referrals__information" iconId="id_warning_icon">
          <div className="c-referrals__information-content">
            <span className="c-referrals__information-text">No referrals</span>
            <span className="c-referrals__information-text">You currently do not have any referral projects</span>
          </div>
        </InformationBox>
      )}
    </div>
  );
};

export { ReferralsTable };
