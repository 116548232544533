import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getItemFromLocalStorage, removeItemFromLocalStorage, useCookie } from '@netfront/common-library';
import { useBackofficeSiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useGetPartner, useToast, useProductsSiteUrls, useGetProduct } from 'hooks';
import { IDBPartner, IDBPartnerTheme } from 'interfaces';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

export function CachingEntitiesProvider({ children }: CachingEntitiesContextProps) {
  const LOCAL_STORAGE_PARTNER_ID = 'partner';
  const { push, pathname } = useRouter();
  const { isDomainReady } = useDomain();
  const sessionPartnerId = getItemFromLocalStorage(LOCAL_STORAGE_PARTNER_ID);

  const { handleToastError } = useToast();

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { getProduct } = useGetProduct();

  const { getBaseUrl, getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const { getDashboardUrl: getProductDashboardUrl } = useProductsSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_PRODUCT_LOCAL_PORT,
  });

  const [cmsBaseUrl, setCmsBaseUrl] = useState<string>('');
  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [partner, setPartner] = useState<IDBPartner>();
  const [partnerTheme, setPartnerTheme] = useState<IDBPartnerTheme>({
    brandAccentPrimary: '',
    presignedUrl: '',
  });

  const { handleGetPartner: executeGetPartner, isLoading: isGetPartnerLoading = false } = useGetPartner({
    onCompleted: ({ partnerConnection }) => {
      setPartner(partnerConnection);
      setPartnerTheme({
        brandAccentPrimary: partnerConnection.brandAccentPrimary ?? '',
        presignedUrl: partnerConnection.logo?.presignedUrl ?? '',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const clearCurrentPartner = () => {
    removeItemFromLocalStorage(LOCAL_STORAGE_PARTNER_ID);
    window.location.reload();
  };

  const handleUpdatePartner = (partnerData: IDBPartner) => {
    setPartner(partnerData);
  };

  const handleUpdatePartnerTheme = (partnerThemeData: IDBPartnerTheme) => {
    setPartnerTheme(partnerThemeData);
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    switch (getProduct()) {
      case 'EKARDO':
        setIsProduct(false);
        setDashboardUrl(getDashboardUrl());
        break;
      case 'KANZI':
      case 'QUICKCODES':
        setIsProduct(true);
        setDashboardUrl(getProductDashboardUrl());
        break;
    }

    setCmsBaseUrl(getBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (pathname.contains('/management/')) return;

    if (!sessionPartnerId) {
      void push('/dashboard');
      return;
    }

    executeGetPartner({
      id: Number(sessionPartnerId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionPartnerId]);

  const isLoading = isGetPartnerLoading;

  return (
    <div>
      <CachingEntitiesContext.Provider
        value={{
          clearCurrentPartner,
          cmsBaseUrl,
          dashboardUrl,
          isProduct,
          partner,
          partnerTheme,
          updatePartner: handleUpdatePartner,
          updatePartnerTheme: handleUpdatePartnerTheme,
        }}
      >
        {isLoading ? <Spinner isLoading={true} /> : children}
      </CachingEntitiesContext.Provider>
    </div>
  );
}
