import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { IBarChartProps } from './BarChart.interfaces';

const BarChart = ({ datasets, displayedValue, label, labels, legends }: IBarChartProps) => {
  const options = {
    responsive: true,
    scale: {
      ticks: {
        precision: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const data = {
    labels,
    datasets: datasets,
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  return (
    <div className="barchart-container">
      <div className="barchart-legend-container">
        <h4>{label}</h4>
        <h3>{displayedValue}</h3>
        <ul className="barchart-legend-ul">
          {legends.map((r) => (
            <li key={r.label}>
              <div className="barchart-dot-legend" style={{ backgroundColor: r.color }}></div>
              <p>{r.label}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="barchart-chart">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export { BarChart };
