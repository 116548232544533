import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_PARTNER_LOGO_ASSET_MUTATION } from './useCreatePartnerLogoAsset.graphql';
import {
  ICreatePartnerLogoAssetMutationGraphQLResponse,
  ICreatePartnerLogoAssetMutationVariables,
  IHandleCreatePartnerLogoAssetParams,
  IUseCreatePartnerLogoAssetOptions,
  IUseCreatePartnerLogoAsset,
} from './useCreatePartnerLogoAsset.interfaces';

const useCreatePartnerLogoAsset = (options?: IUseCreatePartnerLogoAssetOptions): IUseCreatePartnerLogoAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreatePartnerLogoAssetOptions);

  const [executeCreatePartnerLogoAsset, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreatePartnerLogoAssetMutationGraphQLResponse,
    ICreatePartnerLogoAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_PARTNER_LOGO_ASSET_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            createPartnerAsset: { asset: assetConnection, signedUrl },
          },
        } = data;

        const newAsset = {
          ...assetConnection,
          signedUrl,
        };

        void onCompleted(newAsset);
      },
      onError,
    },
    token,
  });

  const handleCreatePartnerLogoAsset = ({ input }: IHandleCreatePartnerLogoAssetParams) => {
    void executeCreatePartnerLogoAsset({
      variables: {
        input,
      },
    });
  };

  return {
    handleCreatePartnerLogoAsset,
    isLoading,
  };
};

export { useCreatePartnerLogoAsset };
