import { useState } from 'react';

import { Icon } from '@netfront/ui-library';

import { AvailableChart, ChartPickerProps } from './ChartPicker.interfaces';

const ChartPicker = ({ availableCharts, defaultSelection, onActiveSelectionChange }: ChartPickerProps) => {
  const [activeSelection, setActiveSelection] = useState<AvailableChart>(defaultSelection);

  const IconMatch = {
    Doughnut: 'id_compact_grid_view_icon',
    Line: 'id_compact_list_view_icon',
    Table: 'id_compact_tab_icon',
    Bar: 'id_compact_grid_view_icon',
  };

  const onSelectionChange = (value: AvailableChart) => {
    setActiveSelection(value);
    onActiveSelectionChange?.(value);
  };

  return (
    <div className="chart-picker-container">
      <ul className="chart-picker-ul">
        {availableCharts.map((r) => (
          <li onClick={() => onSelectionChange(r)} className={activeSelection == r ? 'active' : ''} key={r}>
            {<Icon id={IconMatch[AvailableChart[r]]} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export { ChartPicker };
