import { useEffect, ReactNode } from 'react';

import { getAccessTokenCookie, useJwtDecoder } from '@netfront/common-library';
import { useRouter } from 'next/router';

interface IsAdminProps {
  children: ReactNode;
}

const IsAdmin = ({ children }: IsAdminProps) => {
  const ADMINISTRATOR = 'Administrator';

  const { push } = useRouter();
  const { getDecodedJwt } = useJwtDecoder();
  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      void push('/');
    }
    const decoded = getDecodedJwt(String(accessToken));

    const { role } = decoded as { role: string };

    if (role !== ADMINISTRATOR) {
      void push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

// eslint-disable-next-line import/no-default-export
export default IsAdmin;
