import { useEffect, useState } from 'react';

import { Card, Icon, Skeleton, Tooltip } from '@netfront/ui-library';
import Link from 'next/link';

import { TrendCardProps } from './TrendCard.interfaces';

const TrendCard = ({ count, href, icon, text, trend, navigationText, isFailed }: TrendCardProps) => {
  const [isPositiveTrend, setIsPositiveTrend] = useState<boolean>(false);
  useEffect(() => {
    if (!trend) return;
    setIsPositiveTrend(trend > 0);
  }, [trend]);

  return (
    <Card additionalClassNames="trend-card">
      <div className="trend-card-header-container">
        <div>
          {isFailed ? (
            <>
              <Icon className="icon-trend-card" id="id_invalid_icon"></Icon>
              <p>Error while retrieving the data. Contact the assistance or try again later.</p>
            </>
          ) : (
            <>
              <Icon className="icon-trend-card" id={icon}></Icon>
              <div>
                <p className="entity-text">{text}</p>
                <p className="entity-count">{count ? count : <Skeleton />}</p>
                {trend ? (
                  <div className="trend-container">
                    <Icon id="id_insights_icon" />
                    <p className={`trend ${isPositiveTrend ? 'positive' : 'negative'}`}>{Math.abs(trend)}%</p>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </div>
            </>
          )}
        </div>
        <Tooltip iconId="id_tooltip_icon" text={`Display the total number of ${text} in the system`}></Tooltip>
      </div>
      <div className="separator" />
      <Link className="trend-card-link" href={href}>
        {navigationText}
        <div className="icon">
          <Icon id="id_arrow_icon" />
        </div>
      </Link>
    </Card>
  );
};

export { TrendCard };
