import { useState, useEffect, useContext } from 'react';

import { IGeladaOrganisation } from '@netfront/gelada-identity-library';
import {
  AvatarBreadcrumbInformationBoxTemplate,
  Label,
  IListItem,
  SelectWithSearch,
  SideBar,
  Spacing,
  TabSet,
  Tooltip,
  ITab,
} from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useToast } from 'hooks';

import { ProjectsTable } from '../Tables';

import { PageLayout } from '../../../PageLayout';
import { AccountSidebarGeneralView } from '../../../Views';

interface IOrganisationListItem extends IListItem {
  key: string;
}

const PartnerManagementDashboardPage = () => {
  const { handleToastSuccess } = useToast();
  const { partner } = useContext(CachingEntitiesContext);
  const [isAddOrganisationSideBarOpen, setIsAddOrganisationSideBarOpen] = useState<boolean>(false);
  const [isSearchContentVisible, setIsSearchContentVisible] = useState<boolean>(false);
  const [organisationsDropdownItems, setOrganisationsDropdownItems] = useState<IOrganisationListItem[]>([]);
  const [isOrganisationLinked, setIsOrganisationLinked] = useState<boolean>(false);
  const [organisationItem, setOrganisationItem] = useState<IOrganisationListItem>();

  const handleCreateAccount = (account: IGeladaOrganisation) => {
    const { id, name, key } = account;

    const newAccountItem = {
      id: id,
      key: key,
      label: name,
    };

    handleOrganisationSearchItemClick(newAccountItem);

    setOrganisationItem(newAccountItem);

    handleToastSuccess({
      message: `Account created successfully`,
    });

    setIsAddOrganisationSideBarOpen(false);
  };

  const handleLinkAccount = (isLinked: boolean) => {
    setIsOrganisationLinked(isLinked);

    handleToastSuccess({
      message: `Account linked successfully`,
    });
  };

  const handleOrganisationSearchItemClick = (item: IOrganisationListItem) => {
    setOrganisationItem(item);
  };

  useEffect(() => {
    if (!partner) {
      return;
    }

    const {
      organisations: { edges: organisationsEdges },
    } = partner;

    const organisationsList = organisationsEdges.map(({ node }) => node);

    const organisationsSelectSearchList = organisationsList.map(
      ({ id, key, name }): IOrganisationListItem => ({
        id: id,
        key: key,
        label: name,
      }),
    );

    setOrganisationsDropdownItems(organisationsSelectSearchList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner?.organisations]);

  useEffect(() => {
    if (!(organisationItem && isOrganisationLinked)) {
      return;
    }

    setOrganisationsDropdownItems((currentState) => [...currentState, organisationItem]);
  }, [organisationItem, isOrganisationLinked]);

  const tabs: ITab[] = [
    {
      id: '1',
      label: 'General',
      view: () => (
        <AccountSidebarGeneralView
          onClose={() => setIsAddOrganisationSideBarOpen(false)}
          onCreated={handleCreateAccount}
          onLinked={handleLinkAccount}
        />
      ),
      iconId: 'id_general_tab_icon',
    },
  ];

  return (
    <div className="c-partner-management-page">
      <PageLayout meta={{ description: 'Manage partner' }} size={'small'} title="Partner management" hasPrivateLayout>
        <div className="c-partner-management-page__switch-account">
          <Spacing size="x-small">
            <div className="c-partner-management-page__switch-account-label">
              <Label forId="organisations" labelText="Switch account" />
              <Tooltip iconId="id_tooltip_icon" placement="right" text="Select organisation" />
            </div>
          </Spacing>

          <SelectWithSearch
            additionalClassNames="c-partner-management-page__switch-account-select"
            buttonText="Select organisation"
            countText="organisations"
            customButtonIconId="id_add_circle_icon"
            customButtonText="Add new account"
            isDisplaySearchContent={isSearchContentVisible}
            searchList={organisationsDropdownItems}
            isAvatarVisible
            onCustomButtonClick={() => setIsAddOrganisationSideBarOpen(true)}
            onDisplaySearchContent={() => setIsSearchContentVisible(!isSearchContentVisible)}
            onSearchItemClick={(e) => {
              const dropDownItem = organisationsDropdownItems.find((r) => r.id === e);
              if (dropDownItem) {
                setOrganisationItem(dropDownItem);
              }
            }}
          />
        </div>

        <AvatarBreadcrumbInformationBoxTemplate breadcrumbItems={[]} message={`Manage client groups and spaces`} title="Dashboard" />

        {organisationItem && <ProjectsTable organisationId={Number(organisationItem.id)} organisationKey={organisationItem.key} />}
      </PageLayout>

      <SideBar isSideBarOpen={isAddOrganisationSideBarOpen} isCloseButtonVisible onClose={() => setIsAddOrganisationSideBarOpen(false)}>
        {isAddOrganisationSideBarOpen && <TabSet defaultActiveTabId="1" tabs={tabs} />}
      </SideBar>
    </div>
  );
};

export { PartnerManagementDashboardPage };
