import { ButtonIconOnly, Input, Spacing, Spinner } from '@netfront/ui-library';
import noop from 'lodash/noop';

import { TransactionSidebarViewProps } from './TransactionSidebarView.interfaces';

const TransactionSidebarView = ({ onClose, selectedTransaction }: TransactionSidebarViewProps) => {
  const { description, amount, status } = selectedTransaction ?? {};

  return (
    <div className="c-dashboard-sidebar-general-view">
      <Spinner isLoading={false} />

      <div className="c-sidebar__close-button">
        <ButtonIconOnly iconId="id_close_icon" text="Close sidebar" onClick={onClose} />
      </div>

      <>
        <Spacing>
          <Input id="plan" labelText="Plan" name="plan" type="text" value={description} isDisabled onChange={noop} />
        </Spacing>

        <Spacing>
          <Input id="amount" labelText="Amount" name="amount" type="text" value={amount} isDisabled onChange={noop} />
        </Spacing>

        <Spacing>
          <Input
            id="project-details"
            labelText="Project details"
            name="project-details"
            type="text"
            value={status}
            isDisabled
            onChange={noop}
          />
        </Spacing>
      </>
    </div>
  );
};

export { TransactionSidebarView };
