import format from 'date-fns/format';

import { IGetTransactionsTableDataParams, ITransactionsTableData } from './TransactionsTable.interfaces';

const getTransactionsTableData = ({ transactions }: IGetTransactionsTableDataParams): ITransactionsTableData[] => {
  return transactions.map(({ createdAtUtc, customer, amount, description, email, status }): ITransactionsTableData => {
    return {
      amount: amount / 100,
      customer,
      description: description ?? '',
      email,
      paymentDate: `${format(Date.parse(String(createdAtUtc)), 'dd MMMM yyyy')}`,
      status,
    };
  });
};

export { getTransactionsTableData };
