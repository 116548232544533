import React, { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { IDBGeladaProject } from '@netfront/gelada-identity-library';
import {
  Button,
  InformationBox,
  ITab,
  Pagination,
  Spinner,
  SearchFilter,
  SideBar,
  Spacing,
  Table,
  TabSet,
  Toolbar,
} from '@netfront/ui-library';
import { useToast, useProtectedRoute, useGetGeladaOrganisationProjects } from 'hooks';
import last from 'lodash.last';

import { PROJECTS_TABLE_COLUMNS } from './ProjectsTable.constants';
import { GetProjectsTableData } from './ProjectsTable.helpers';
import { IProjectsTableData, ProjectsTableProps } from './ProjectsTable.interfaces';

import { DashboardSidebarGeneralView } from '../../../../Views';

const ProjectsTable = ({ organisationKey, organisationId }: ProjectsTableProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const { getProjectsTableData } = GetProjectsTableData();

  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();

  const [allProjects, setAllProjects] = useState<IDBGeladaProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<IDBGeladaProject[]>([]);
  const [filter, setFilter] = useState<string>();
  const [lastCursor, setLastCursor] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [projectsTableData, setProjectsTableData] = useState<IProjectsTableData[]>([]);
  const [selectedProject, setSelectedProject] = useState<IDBGeladaProject>();
  const [totalOrganisationProjects, setTotalOrganisationProjects] = useState<number>(0);

  const token = getAccessTokenCookie();

  const {
    handleGetGeladaOrganisationProjects,
    handleFetchMoreGetGeladaOrganisationProjects,
    isLoading: isGetOrganisationProjectsLoading,
  } = useGetGeladaOrganisationProjects({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ projectsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const organisationProjects = edges.map(({ node }) => node);

      setFilteredProjects(organisationProjects);
      setAllProjects(organisationProjects);
      setIsLoadMoreDisabled(organisationProjects.length >= totalCount || totalCount <= pageSize);
      setTotalOrganisationProjects(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleAddNewProjectClick = () => {
    setSelectedProject(undefined);
    setIsSideBarOpen(true);
  };

  const handleCreatedProject = (project: IDBGeladaProject) => {
    setAllProjects((currentState) => [...currentState, project]);
    setFilteredProjects((currentState) => [...currentState, project]);

    handleSideBarClose();

    handleToastSuccess({
      message: 'Project created successfully',
    });
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');

    setFilteredProjects(allProjects);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);

    setFilteredProjects(allProjects.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase())));
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreGetGeladaOrganisationProjects({
      after: lastCursor,
      first: pageSize,
      organisationKey: organisationKey,
    });
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
  };

  const handleUpdatedProject = (project: IDBGeladaProject) => {
    setAllProjects((currentState): IDBGeladaProject[] => {
      return currentState.map((currentProject): IDBGeladaProject => {
        return currentProject.id === project.id ? project : currentProject;
      });
    });

    setFilteredProjects((currentState): IDBGeladaProject[] => {
      return currentState.map((currentProject): IDBGeladaProject => {
        return currentProject.id === project.id ? project : currentProject;
      });
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Project updated successfully',
    });
  };

  useEffect(() => {
    if (!(isAuthenticated && organisationKey)) {
      return;
    }

    void handleGetGeladaOrganisationProjects({
      organisationKey: organisationKey,
      first: pageSize,
      shouldIncludeProjectTheme: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, organisationKey, pageSize]);

  useEffect(() => {
    setProjectsTableData(
      getProjectsTableData({
        projects: filteredProjects,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedProject(filteredProjects.find(({ id: projectId }) => id === projectId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjects]);

  const tabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: '1',
      label: 'General',
      view: () => (
        <DashboardSidebarGeneralView
          organisationId={organisationId}
          project={selectedProject}
          onClose={handleSideBarClose}
          onCreated={handleCreatedProject}
          onUpdated={handleUpdatedProject}
        />
      ),
    },
  ];

  return (
    <div className="c-projects-table">
      <Spacing>
        <Toolbar
          childrenEnd={<Button text="Add new +" type="button" variant="primary-inverse" onClick={handleAddNewProjectClick} />}
          childrenStart={
            <SearchFilter
              id="projects-filter"
              isDisabled={false}
              labelText="projects filter"
              name="searchProjects"
              placeholder="Project name"
              type="text"
              value={filter}
              isLabelHidden
              onChange={handleFilterChange}
              onClear={handleFilterClear}
              onSearch={handleFilterSearch}
            />
          }
        />
      </Spacing>

      {isGetOrganisationProjectsLoading ? (
        <Spinner isLoading={isGetOrganisationProjectsLoading} />
      ) : projectsTableData.length > 0 ? (
        <>
          <Spacing>
            <Table additionalClassNames="c-projects-table" columns={PROJECTS_TABLE_COLUMNS} data={projectsTableData} hasButton />
          </Spacing>

          <Pagination
            isLoadMoreDisabled={isLoadMoreDisabled}
            pageSize={pageSize}
            totalItems={totalOrganisationProjects}
            onPageChange={async () => {
              await handleFetchMoreGetGeladaOrganisationProjects({
                after: lastCursor,
                first: pageSize,
                organisationKey: organisationKey,
              });
            }}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <InformationBox additionalClassNames="c-projects-table__information" iconId="id_warning_icon">
          <div className="c-projects-table__information-content">
            <span className="c-projects-table__information-text">
              You currently do not have no projects, click add new above to get started
            </span>
          </div>
        </InformationBox>
      )}

      <SideBar isSideBarOpen={isSideBarOpen} onClose={handleSideBarClose}>
        {isSideBarOpen && <TabSet defaultActiveTabId="1" tabs={tabs} />}
      </SideBar>
    </div>
  );
};

export { ProjectsTable };
