import { useEffect, useState } from 'react';

import {
  IGetGeladaInsightsOnCompletedResponse,
  IGetMuriquiInsightsOnCompletedResponse,
  useGetGeladaInsights,
  useGetMuriquiInsights,
} from 'hooks';
import { DailyCount, PlanUsage } from 'interfaces';

import { GenericInsightsProps } from './GenericInsights.interfaces';
import { PaidProjects } from './PaidProjects/PaidProjects';
import { PartnerActive } from './PartnerActive/PartnerActive';
import { TotalProjects } from './TotalProjects/TotalProjects';

const GenericInsights = ({ period, product }: GenericInsightsProps) => {
  const [activePartners, setActivePartners] = useState<number>(0);
  const [projectsCreationDuringComparisonPeriod, setProjectsCreationDuringComparisonPeriod] = useState<DailyCount[]>([]);
  const [projectsCreationDuringSelectedPeriod, setProjectsCreationDuringSelectedPeriod] = useState<DailyCount[]>([]);
  const [payingProjectCount, setPayingProjectCount] = useState<number>(0);
  const [planUsages, setPlanUsages] = useState<PlanUsage[]>([]);

  const onGetGeladaInsightsCompleted = (data: IGetGeladaInsightsOnCompletedResponse) => {
    const { activePartners: ap, projectsCreationDuringComparisonPeriod: comparison, projectsCreationDuringSelectedPeriod: selected } = data;
    setActivePartners(ap);
    setProjectsCreationDuringComparisonPeriod(comparison);
    setProjectsCreationDuringSelectedPeriod(selected);
  };

  const onGetMuriquiInsightsCompleted = (data: IGetMuriquiInsightsOnCompletedResponse) => {
    const { payingProject, usages } = data;
    setPlanUsages(usages);
    setPayingProjectCount(payingProject);
  };

  const { handleGetGeladaInsights } = useGetGeladaInsights({ onCompleted: onGetGeladaInsightsCompleted });
  const { handleGetMuriquiInsights } = useGetMuriquiInsights({ onCompleted: onGetMuriquiInsightsCompleted });

  useEffect(() => {
    const { end, start } = period;
    handleGetGeladaInsights({
      end,
      product,
      start,
    });

    handleGetMuriquiInsights({
      end,
      product,
      start,
    });
  }, [period]);

  return (
    <>
      <PartnerActive count={activePartners} />
      <TotalProjects
        comparisonPeriodDailyCount={projectsCreationDuringComparisonPeriod}
        selectedPeriodDailyCount={projectsCreationDuringSelectedPeriod}
      />
      <PaidProjects planUsages={planUsages} totalCount={payingProjectCount} />
    </>
  );
};
export { GenericInsights };
