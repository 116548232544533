import React, { ChangeEvent, useEffect, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import {
  InformationBox,
  Pagination,
  ITab,
  SearchFilter,
  SideBar,
  Spacing,
  Spinner,
  Table,
  TabSet,
  Toolbar,
  Button,
} from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useGetTeam, useToast, useProtectedRoute } from 'hooks';
import { IDBPartnerTeam } from 'interfaces';
import last from 'lodash.last';

import { TEAMS_TABLE_COLUMNS } from './TeamsTable.constants';
import { getTeamsTableData } from './TeamsTable.helpers';
import { ITeamsTableData } from './TeamsTable.interfaces';

import { AddTeamSidebarView, TeamSidebarGeneralView } from '../../../../Views';

const TeamsTable = () => {
  const { handleToastError } = useToast();

  const { partner } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();

  const [allTeams, setAllTeams] = useState<IDBPartnerTeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<IDBPartnerTeam[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarEditUserOpen, setIsSideBarEditUserOpen] = useState<boolean>(false);
  const [isSideBarAddUserOpen, setIsSideBarAddUserOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedTeam, setSelectedTeam] = useState<IDBPartnerTeam>();
  const [totalTeams, setTotalTeams] = useState<number>(0);
  const [teamsTableData, setTeamsTableData] = useState<ITeamsTableData[]>([]);

  const token = getAccessTokenCookie();

  const {
    handleGetTeam,
    handleFetchMoreTeam,
    isLoading: isGetTeamLoading,
  } = useGetTeam({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ partnerTeamConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const partnerTeams = edges.map(({ node }) => node);

      setFilteredTeams(partnerTeams);
      setAllTeams(partnerTeams);

      setIsLoadMoreDisabled(partnerTeams.length >= totalCount || totalCount <= pageSize);
      setTotalTeams(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');

    setFilteredTeams(allTeams);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);

    setFilteredTeams(
      allTeams.filter(
        ({
          user: {
            firstname,
            lastname,
            credential: { email },
          },
        }) =>
          firstname.toLowerCase().includes(value.toLowerCase()) ||
          email.toLowerCase().includes(value.toLowerCase()) ||
          lastname.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const handleSideBarClose = () => {
    setIsSideBarEditUserOpen(false);
    setIsSideBarAddUserOpen(false);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreTeam({
      after: lastCursor,
      first: pageSize,
      partnerId: Number(partner?.id),
    });
  };

  const handleSideBarAddUserOpen = () => {
    setIsSideBarAddUserOpen(true);
  };

  useEffect(() => {
    setTeamsTableData(
      getTeamsTableData({
        teams: filteredTeams,
        onSettingsButtonClick: (id) => {
          setIsSideBarEditUserOpen(true);
          setSelectedTeam(filteredTeams.find(({ user: { id: userId } }) => id === userId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTeams]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetTeam({
      partnerId: Number(partner?.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pageSize]);

  const editUserTabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: '1',
      label: 'General',
      view: () => <TeamSidebarGeneralView selectedTeam={selectedTeam} onClose={handleSideBarClose} />,
    },
  ];

  const addUserTabs: ITab[] = [
    {
      iconId: 'id_general_tab_icon',
      id: '1',
      label: 'General',
      view: () => <AddTeamSidebarView onClose={handleSideBarClose} />,
    },
  ];
  const isSideBarOpen = isSideBarEditUserOpen || isSideBarAddUserOpen;
  return (
    <div className="c-teams-table">
      <Spacing>
        <Toolbar
          childrenStart={
            <>
              <SearchFilter
                id="teams-filter"
                isDisabled={false}
                labelText="Teams filter"
                name="searchTeams"
                placeholder="Filter the users"
                type="text"
                value={filter}
                isLabelHidden
                onChange={handleFilterChange}
                onClear={handleFilterClear}
                onSearch={handleFilterSearch}
              />
              <Button text="Add new +" type="button" variant="secondary" onClick={handleSideBarAddUserOpen} />
            </>
          }
        />
      </Spacing>

      {isGetTeamLoading ? (
        <Spinner isLoading={isGetTeamLoading} />
      ) : teamsTableData.length > 0 ? (
        <>
          <Spacing>
            <Table additionalClassNames="c-teams-table" columns={TEAMS_TABLE_COLUMNS} data={teamsTableData} hasButton />
          </Spacing>
          <Pagination
            isLoadMoreDisabled={isLoadMoreDisabled}
            pageSize={pageSize}
            totalItems={totalTeams}
            onPageChange={async () => {
              await handleFetchMoreTeam({
                after: lastCursor,
                first: pageSize,
                partnerId: Number(partner?.id),
              });
            }}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <InformationBox additionalClassNames="c-Teams-table__information" iconId="id_warning_icon">
          <div className="c-teams-table__information-content">
            <span className="c-teams-table__information-text">You currently do not have no users, click add new above to get started</span>
          </div>
        </InformationBox>
      )}

      <SideBar isSideBarOpen={isSideBarOpen} onClose={handleSideBarClose}>
        {isSideBarEditUserOpen && <TabSet defaultActiveTabId="1" tabs={editUserTabs} />}
        {isSideBarAddUserOpen && <TabSet defaultActiveTabId="1" tabs={addUserTabs} />}
      </SideBar>
    </div>
  );
};

export { TeamsTable };
