import { useState } from 'react';

import { AvatarBreadcrumbInformationBoxTemplate, NavigationButton, Spacing } from '@netfront/ui-library';
import format from 'date-fns/format';
import { IDBPayout } from 'interfaces';

import { PayoutsTable, TransactionsTable } from '../Tables';

import { PageLayout } from '../../../PageLayout';

const PartnerManagementPayoutsPage = () => {
  const [isTransactionsTableVisible, setIsTransactionsTableVisible] = useState<boolean>(false);
  const [selectedPayout, setSelectedPayout] = useState<IDBPayout>();

  const handleDisplayTransactionsTable = () => {
    setIsTransactionsTableVisible(!isTransactionsTableVisible);
  };

  const handleDisplayPayout = (payout: IDBPayout) => {
    setSelectedPayout(payout);
  };

  return (
    <div className="c-partner-management-page">
      <PageLayout meta={{ description: 'Partner payouts' }} size={'small'} title="Partner management payouts" hasPrivateLayout>
        <AvatarBreadcrumbInformationBoxTemplate
          breadcrumbItems={
            !isTransactionsTableVisible
              ? []
              : [
                  {
                    content: 'Monthly breakdown',
                    key: '1',
                  },
                ]
          }
          message={
            !isTransactionsTableVisible
              ? 'Please find payouts by month below. Click the month to see individual payments for the month.'
              : `Total amount paid ${selectedPayout?.amount ?? 0} AUD`
          }
          title={
            !isTransactionsTableVisible
              ? `Payouts`
              : `${format(Date.parse(String(selectedPayout?.start)), 'do MMMM')} - ${format(
                  Date.parse(String(selectedPayout?.end)),
                  'do MMMM',
                )}`
          }
        />

        {!isTransactionsTableVisible ? (
          <PayoutsTable onDisplayPayout={handleDisplayPayout} onDisplayTransactionsTable={handleDisplayTransactionsTable} />
        ) : (
          <>
            <Spacing size="large">
              <NavigationButton
                additionalClassNames="c-action__back-to-action"
                direction="previous"
                iconId="id_caret_icon"
                rotationCssSuffix="90"
                text="Return to payouts"
                onClick={handleDisplayTransactionsTable}
              />
            </Spacing>

            <TransactionsTable
              selectedPayoutTransactions={selectedPayout?.transactions ?? []}
              onDisplayTransactionsTable={handleDisplayTransactionsTable}
            />
          </>
        )}
      </PageLayout>
    </div>
  );
};

export { PartnerManagementPayoutsPage };
