import { useState } from 'react';

import { Card } from '@netfront/ui-library';

import { AvailableChart, ChartPicker, LineChart } from 'components/Charts';
import { IChartKeyComponent, ILegend } from 'components/Charts/Charts.interfaces';
import { ILineChartDataset } from 'components/Charts/LineChart/LineChart.interfaces';

import { DailyScansProps } from './DailyScans.interfaces';

const DailyScans = ({ dailyScans }: DailyScansProps) => {
  const LABEL = 'Daily scans projects';

  const [activeChart, setActiveChart] = useState<AvailableChart>(AvailableChart.Line);

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const PRIMARY_COLOR = '#972868';

  const labels = dailyScans.map((r) => r.date.toString());

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: PRIMARY_COLOR, borderColor: PRIMARY_COLOR, data: dailyScans.map((r) => r.count), label: 'Daily scans' },
  ];

  const legends: ILegend[] = [
    {
      color: PRIMARY_COLOR,
      label: 'Daily scans',
    },
  ];

  const availableCharts: IChartKeyComponent[] = [
    { value: AvailableChart.Line, component: <LineChart datasets={lineChart} label={LABEL} labels={labels} legends={legends} /> },
  ];

  return (
    <Card additionalClassNames="insight-card">
      <ChartPicker
        availableCharts={availableCharts.map((r) => r.value)}
        defaultSelection={activeChart}
        onActiveSelectionChange={onActiveChartChange}
      />
      {availableCharts.find((r) => r.value === activeChart)?.component}
    </Card>
  );
};

export { DailyScans };
