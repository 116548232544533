import { ChangeEvent, useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { InformationBox, Pagination, Select, Spinner, Spacing, Table, Toolbar } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { useGetPayouts, useGetProduct, useToast } from 'hooks';
import { IDBPayout } from 'interfaces';
import last from 'lodash.last';

import { PAYOUTS_TABLE_COLUMNS } from './PayoutsTable.constants';
import { getPayoutsTableData } from './PayoutsTable.helpers';
import { IPayoutsTableData, PayoutsTableProps } from './PayoutsTable.interfaces';

const PayoutsTable = ({ onDisplayTransactionsTable, onDisplayPayout }: PayoutsTableProps) => {
  const { handleToastError } = useToast();
  const { partner } = useContext(CachingEntitiesContext);

  const { getAccessTokenCookie } = useCookie();
  const { getProduct } = useGetProduct();

  const token = getAccessTokenCookie();

  const [filteredPayouts, setFilteredPayouts] = useState<IDBPayout[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [payoutsTableData, setPayoutsTableData] = useState<IPayoutsTableData[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('This month');
  const [totalPayouts, setTotalPayouts] = useState<number>(0);
  const [dateRange, setDateRange] = useState<{ end: Date; start: Date }>(getDates());
  const {
    handleGetPayouts,
    handleFetchMorePayouts,
    isLoading: isGetPayoutsLoading,
  } = useGetPayouts({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ partnerPayoutsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const partnerPayouts = edges.map(({ node }) => node);
      const partnerPayoutsId = partnerPayouts.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });

      setFilteredPayouts(partnerPayoutsId);
      setIsLoadMoreDisabled(partnerPayoutsId.length >= totalCount || totalCount <= pageSize);
      setTotalPayouts(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    if (!partner) return;
    const { id } = partner;
    const { end, start } = dateRange;
    const product = getProduct();
    if (!product) return;
    void handleFetchMorePayouts({
      after: lastCursor,
      first: pageSize,
      partnerId: id,
      end,
      start,
      product,
    });
  };

  function getDates(value: string | null = null): { end: Date; start: Date } {
    const now = new Date();

    if (!value) return { start: startOfMonth(now), end: endOfMonth(now) };
    switch (value) {
      case 'This month':
        return { start: startOfMonth(now), end: endOfMonth(now) };
      case 'Last month':
        return { start: startOfMonth(now.setMonth(now.getMonth() - 1)), end: endOfMonth(now) };
      case 'Last quarter':
        return {
          start: startOfQuarter(now.setMonth(now.getMonth() - 3)),
          end: endOfQuarter(now),
        };
      case 'Last year':
        return { start: startOfYear(now), end: endOfYear(now) };
      default:
        return { start: startOfMonth(now), end: endOfMonth(now) };
    }
  }

  const handleSelectPeriod = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedPeriod(value);
    setDateRange(getDates(value));
  };

  useEffect(() => {
    const product = getProduct();
    if (!partner || !product) return;

    const { end, start } = dateRange;
    const { id } = partner;

    handleGetPayouts({
      partnerId: id,
      product: 'QUICKCODES',
      end,
      start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner, dateRange]);

  useEffect(() => {
    setPayoutsTableData(
      getPayoutsTableData({
        payouts: filteredPayouts,
        onBreakdownButtonClick: (id: number) => {
          // TODO: Add selected payout transactions to state when id is added to the payouts query
          onDisplayTransactionsTable();
          onDisplayPayout(filteredPayouts.find(({ id: payoutId }) => id === payoutId) as IDBPayout);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod, filteredPayouts]);

  return (
    <div className="c-payouts-table">
      <Spacing>
        <Toolbar
          childrenEnd={
            <Select
              additionalClassNames="c-payouts-table__period-select"
              id="period"
              labelText="Period"
              name="period"
              options={[
                { id: 1, name: 'This month', value: 'This month' },
                { id: 2, name: 'Last month', value: 'Last month' },
                { id: 3, name: 'Last quarter', value: 'Last quarter' },
                { id: 4, name: 'Last year', value: 'Last year' },
                { id: 5, name: 'All', value: 'All' },
              ]}
              value={selectedPeriod}
              isLabelHidden
              onChange={handleSelectPeriod}
            />
          }
          childrenStart={<span />}
        />
      </Spacing>

      {isGetPayoutsLoading ? (
        <Spinner isLoading={isGetPayoutsLoading} />
      ) : payoutsTableData.length > 0 ? (
        <>
          <Spacing>
            <Table additionalClassNames="c-Payouts-table" columns={PAYOUTS_TABLE_COLUMNS} data={payoutsTableData} />
          </Spacing>

          <Pagination
            isLoadMoreDisabled={isLoadMoreDisabled}
            pageSize={pageSize}
            totalItems={selectedPeriod === 'All' ? totalPayouts : filteredPayouts.length}
            onPageChange={async () => {
              if (!partner) return;
              const { id } = partner;
              const { end, start } = dateRange;
              const product = getProduct();
              if (!product) return;
              await handleFetchMorePayouts({
                after: lastCursor,
                first: pageSize,
                partnerId: id,
                end,
                start,
                product,
              });
            }}
            onPageSizeChange={handlePageSizeChange}
          />
        </>
      ) : (
        <InformationBox additionalClassNames="c-projects-table__information" iconId="id_warning_icon">
          <div className="c-projects-table__information-content">
            <span className="c-projects-table__information-text">No Payouts</span>
            <span className="c-projects-table__information-text">You currently do not have any payouts within the selected period</span>
          </div>
        </InformationBox>
      )}
    </div>
  );
};

export { PayoutsTable };
