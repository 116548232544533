const TITLE_CHARACTER_MAX_LENGTH = 50;

const DESCRIPTION_CHARACTER_MAX_LENGTH = 500;

const MAX_UPLOAD_FILE_SIZE = 200;

const dashboardSidebarGeneralViewConstants = Object.freeze({
  titleCharacterMaxLength: TITLE_CHARACTER_MAX_LENGTH,
  maxUploadFileSize: MAX_UPLOAD_FILE_SIZE,
  descriptionCharacterMaxLength: DESCRIPTION_CHARACTER_MAX_LENGTH,
});

export { dashboardSidebarGeneralViewConstants };
