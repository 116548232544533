import React, { ChangeEvent, useEffect, useState } from 'react';

import { SearchFilter, Spacing, Table, Toolbar } from '@netfront/ui-library';
import { IDBInvoiceTransaction } from 'interfaces';

import { TRANSACTIONS_TABLE_COLUMNS } from './TransactionsTable.constants';
import { getTransactionsTableData } from './TransactionsTable.helpers';
import { ITransactionsTableData, TransactionsTableProps } from './TransactionsTable.interfaces';

const TransactionsTable = ({ selectedPayoutTransactions }: TransactionsTableProps) => {
  const [filteredTransactions, setFilteredTransactions] = useState<IDBInvoiceTransaction[]>(selectedPayoutTransactions);
  const [filter, setFilter] = useState<string>();
  const [transactionsTableData, setTransactionsTableData] = useState<ITransactionsTableData[]>([]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');

    setFilteredTransactions(selectedPayoutTransactions);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);

    setFilteredTransactions(
      selectedPayoutTransactions.filter(({ description }) => description?.toLowerCase().includes(value.toLowerCase())),
    );
  };

  useEffect(() => {
    setTransactionsTableData(
      getTransactionsTableData({
        transactions: filteredTransactions,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTransactions]);

  return (
    <div className="c-transactions-table">
      <Spacing>
        <Toolbar
          childrenStart={
            <SearchFilter
              id="Transactions-filter"
              isDisabled={false}
              labelText="Transactions filter"
              name="searchTransactions"
              placeholder="Search transactions"
              type="text"
              value={filter}
              isLabelHidden
              onChange={handleFilterChange}
              onClear={handleFilterClear}
              onSearch={handleFilterSearch}
            />
          }
        />
      </Spacing>

      <Table additionalClassNames="c-transactions-table" columns={TRANSACTIONS_TABLE_COLUMNS} data={transactionsTableData} />
    </div>
  );
};

export { TransactionsTable };
