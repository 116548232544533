import { ILeftSidebarList } from './ManagementSidebar.interfaces';

const LEFT_SIDEBAR_LIST: ILeftSidebarList[] = [
  {
    iconId: 'id_dashboard_icon',
    name: 'Dashboard',
  },
  {
    iconId: 'id_referrals_icon',
    name: 'Referrals',
  },
  {
    iconId: 'id_payouts_icon',
    name: 'Payouts',
  },
  {
    iconId: 'id_resources_icon',
    name: 'Resources',
  },
  {
    iconId: 'id_support_icon',
    name: 'Support',
  },
  {
    iconId: 'id_team_icon',
    name: 'Team',
  },
  {
    iconId: 'id_settings_icon',
    name: 'Settings',
  },
];

const managementSidebarConstants = Object.freeze({
  leftSidebarList: LEFT_SIDEBAR_LIST,
});

export { managementSidebarConstants };
