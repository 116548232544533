import { IDBCustomerDetail } from 'interfaces';

import { IGetMuriquiProjectsTableDataParams, IMuriquiProjectsTableData } from './MuriquiProjectsTable.interfaces';

const hasAnyPaidPlan = (customerDetail: IDBCustomerDetail) => {
  const { subscriptions } = customerDetail;
  if (subscriptions.length === 0) return false;
  return subscriptions.some((r) => !r.plan.isFree);
};

const GetMuriquiProjectsTableData = () => {
  const GetTableData = ({ projects }: IGetMuriquiProjectsTableDataParams): IMuriquiProjectsTableData[] => {
    return projects.map(({ id, name, customerDetail }): IMuriquiProjectsTableData => {
      return {
        name,
        id,
        settingsButtonData: {
          id,
          isPaid: hasAnyPaidPlan(customerDetail),
        },
      };
    });
  };

  return { GetTableData };
};

const TOOLTIP_CONSTANTS = Object.freeze({
  PAID: 'The project has at least one paid subscription on the product',
  UNPAID: 'The project has not any paid subscription on the product',
});

export { GetMuriquiProjectsTableData, TOOLTIP_CONSTANTS };
