import { useContext } from 'react';

import { CachingEntitiesContext } from 'context';

import { IGetProjectsTableDataParams, IProjectsTableData } from './ProjectsTable.interfaces';

const GetProjectsTableData = () => {
  const { dashboardUrl } = useContext(CachingEntitiesContext);

  const getProjectsTableData = ({ projects, onSettingsButtonClick }: IGetProjectsTableDataParams): IProjectsTableData[] => {
    return projects.map(({ id, name, organisationId }): IProjectsTableData => {
      return {
        name,
        id,
        settingsButtonData: {
          dashboardUrl,
          id,
          organisationId,
          onClick: onSettingsButtonClick,
        },
      };
    });
  };

  return { getProjectsTableData };
};

export { GetProjectsTableData };
