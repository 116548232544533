import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { CREATE_PROJECT_ASSET_MUTATION } from './useCreateProjectAsset.graphql';
import {
  ICreateProjectAssetMutationGraphQLResponse,
  ICreateProjectAssetMutationVariables,
  IHandleCreateProjectAssetParams,
  IUseCreateProjectAssetOptions,
  IUseCreateProjectAsset,
} from './useCreateProjectAsset.interfaces';

const useCreateProjectAsset = (options?: IUseCreateProjectAssetOptions): IUseCreateProjectAsset => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateProjectAssetOptions);

  const [executeCreateProjectAsset, { loading: isLoading }] = useLoggedGeladaMutation<
    ICreateProjectAssetMutationGraphQLResponse,
    ICreateProjectAssetMutationVariables
  >({
    mutation: mutation ?? CREATE_PROJECT_ASSET_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          asset: {
            createAsset: { asset: assetConnection, signedUrl },
          },
        } = data;

        const newAsset = {
          ...assetConnection,
          signedUrl,
        };

        void onCompleted(newAsset);
      },
      onError,
    },
    token,
  });

  const handleCreateProjectAsset = ({ projectId, alt, contentType, fileName, fileSizeInBytes, type }: IHandleCreateProjectAssetParams) => {
    void executeCreateProjectAsset({
      variables: {
        projectId,
        alt,
        contentType,
        fileName,
        fileSizeInBytes,
        type,
      },
    });
  };

  return {
    handleCreateProjectAsset,
    isLoading,
  };
};

export { useCreateProjectAsset };
