/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { IDoughnutProps } from './DoughnutChart.interfaces';

const DoughnutChart = ({ feed, label, borderWith }: IDoughnutProps) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          useLineStyle: true,
          generateLabels: (chart: { data: { datasets: any; labels: { [x: string]: any } } }) => {
            const { datasets } = chart.data;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
            return datasets[0].data.map((data: any, i: string | number) => ({
              text: `${chart.data.labels[i]} ${data}`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
        },
        position: 'bottom',
        display: true,
      },
    },
  };
  const data = {
    labels: feed.map((r) => r.label),
    datasets: [
      {
        label,
        data: feed.map((r) => r.value),
        backgroundColor: feed.map((r) => r.backgroundColor),
        borderColor: feed.map((r) => r.borderColor),
        borderWidth: borderWith ?? 1,
      },
    ],
  };
  return (
    <>
      <h4 className="doughnut-title">{label}</h4>
      <h3 className="doughnut-value">{feed.map((r) => r.value).reduce((a: number, b: number) => a + b, 0)}</h3>
      <div className="doughnut-container">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
        <Doughnut data={data} options={options as any} />
      </div>
    </>
  );
};

export { DoughnutChart };
