import { useState } from 'react';

import { Card } from '@netfront/ui-library';

import { AvailableChart, BarChart, ChartPicker, DoughnutChart } from 'components/Charts';
import { IBarChartDataset } from 'components/Charts/BarChart/BarChart.interfaces';
import { IChartKeyComponent } from 'components/Charts/Charts.interfaces';

import { PaidProjectsProps } from './PaidProjects.interfaces';

const PaidProjects = ({ planUsages, totalCount }: PaidProjectsProps) => {
  const LABEL = 'Paid projects';

  const [activeChart, setActiveChart] = useState<AvailableChart>(AvailableChart.Doughnut);

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const COLORS = ['#972868', '#4bc2ca', '#985dad', '#bded3e', '#ed633e'];

  const labels = planUsages.map((r) => r.name);

  const doughnutData = planUsages.map((r, i) => ({
    backgroundColor: COLORS[i],
    borderColor: COLORS[i],
    label: r.name,
    value: r.count,
  }));

  const barData: IBarChartDataset[] = [];
  planUsages.forEach((r, i) => {
    const data: number[] = [];
    for (let x = 0; x != i; x++) data.push(0);
    data.push(r.count);
    barData.push({
      backgroundColor: [COLORS[i]],
      label: 'Values',
      data: data,
    });
  });

  const legends = planUsages.map((r, i) => ({
    color: COLORS[i],
    label: r.name,
  }));

  const availableCharts: IChartKeyComponent[] = [
    { value: AvailableChart.Doughnut, component: <DoughnutChart feed={doughnutData} label={LABEL} /> },
    {
      value: AvailableChart.Bar,
      component: <BarChart datasets={barData} displayedValue={totalCount} label={LABEL} labels={labels} legends={legends} />,
    },
  ];

  return (
    <Card additionalClassNames="insight-card">
      <ChartPicker
        availableCharts={availableCharts.map((r) => r.value)}
        defaultSelection={activeChart}
        onActiveSelectionChange={onActiveChartChange}
      />
      {availableCharts.find((r) => r.value === activeChart)?.component}
    </Card>
  );
};
export { PaidProjects };
