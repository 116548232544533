import { createContext } from 'react';

import { IDBPartner, IDBPartnerTheme } from 'interfaces';
import noop from 'lodash/noop';

export interface ICachingEntitiesContext {
  clearCurrentPartner: () => void;
  cmsBaseUrl: string | undefined;
  dashboardUrl: string | undefined;
  isProduct: boolean;
  partner: IDBPartner | undefined;
  partnerTheme: IDBPartnerTheme;
  updatePartner: (partner: IDBPartner) => void | undefined;
  updatePartnerTheme: (partnerTheme: IDBPartnerTheme) => void;
}

export const CachingEntitiesContext = createContext<ICachingEntitiesContext>({
  clearCurrentPartner: () => {},
  cmsBaseUrl: undefined,
  dashboardUrl: undefined,
  isProduct: false,
  partner: undefined,
  partnerTheme: {} as IDBPartnerTheme,
  updatePartner: noop,
  updatePartnerTheme: noop,
});

// eslint-disable-next-line import/no-default-export
export default CachingEntitiesContext;
