import { useState } from 'react';

import { Card } from '@netfront/ui-library';

import { AvailableChart, ChartPicker, DoughnutChart, IDoughnutFeed } from 'components/Charts';
import { IChartKeyComponent } from 'components/Charts/Charts.interfaces';

import { PartnerActiveProps } from './PartnerActive.interfaces';

const PartnerActive = ({ count }: PartnerActiveProps) => {
  const LABEL = 'Partners active';

  const [activeChart, setActiveChart] = useState<AvailableChart>(AvailableChart.Doughnut);

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  
  };

  const DEFAULT_COLOR = '#972868';

  const doughnutData: IDoughnutFeed[] = [
    { backgroundColor: DEFAULT_COLOR, label: 'Active partners', value: count, borderColor: DEFAULT_COLOR },
  ];

  const availableCharts: IChartKeyComponent[] = [
    { value: AvailableChart.Doughnut, component: <DoughnutChart feed={doughnutData} label={LABEL} /> },
  ];

  return (
    <Card additionalClassNames="insight-card">
      <ChartPicker
        availableCharts={availableCharts.map((r) => r.value)}
        defaultSelection={activeChart}
        onActiveSelectionChange={onActiveChartChange}
      />
      {availableCharts.find((r) => r.value === activeChart)?.component}
    </Card>
  );
};
export { PartnerActive };
