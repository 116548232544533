import { OperationVariables, QueryTuple } from '@apollo/client';
import { getApolloLink, useApolloLazyQuery, useCookie } from '@netfront/common-library';

import { IUseTamarinLazyQueryInputVariables } from './useTamarinLazyQuery.interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useTamarinLazyQuery = <TData = any, TVariables = OperationVariables | undefined>({
  apolloLink,
  options,
  query,
  token,
}: IUseTamarinLazyQueryInputVariables<TData, TVariables>): QueryTuple<TData, TVariables> => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'tamarin',
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloLazyQuery({
    apiName: 'tamarin',
    apolloLink: apolloLink ?? authenticationLink,
    options,
    query,
  });
};

export { useTamarinLazyQuery };
