import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { IGetGeladaProjectOnCompletedResponse, useGetGeladaProject } from '@netfront/gelada-identity-library';
import { Avatar, Button, Icon, Pagination, SearchFilter, Spacing, Table, ToggleSwitch, Toolbar } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useGetProjectsByProduct } from 'hooks';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import { Column } from 'react-table';

import { GetMuriquiProjectsTableData, TOOLTIP_CONSTANTS } from './MuriquiProjectsTable.helpers';
import { IMuriquiProjectsTableData, IMuriquiProjectsTableProps } from './MuriquiProjectsTable.interfaces';

const MuriquiProjectsTable = ({ product, onLoad }: IMuriquiProjectsTableProps) => {
  const { dashboardUrl } = useContext(CachingEntitiesContext);

  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('');
  const [tableProjects, setTableProjects] = useState<IMuriquiProjectsTableData[]>([]);
  const [lastCursor, setLastCursor] = useState<string>();
  const [isOnlyDisplayingPaidProject, setIsOnlyDisplayingPaidProject] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { GetTableData } = GetMuriquiProjectsTableData();
  const { push } = useRouter();
  const onGetGeladaProjectResponse = (data: IGetGeladaProjectOnCompletedResponse) => {
    const {
      geladaProject: { organisationId, guid },
    } = data;
    const projectUrl = `${String(dashboardUrl)}/${organisationId}/${guid}`;
    void push(projectUrl);
  };

  const { handleGetGeladaProject } = useGetGeladaProject({ onCompleted: onGetGeladaProjectResponse });
  const { handleGetProjectsByProduct, isLoading, handleFetchMoreProjectsByProduct } = useGetProjectsByProduct({
    onCompleted(data) {
      const {
        projects: { edges, totalCount },
      } = data;
      const lastEdge = last(edges);
      setTotalProjects(totalCount ?? 0);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }
      const projects = edges.map((r) => r.node);
      setTableProjects(
        GetTableData({
          projects,
        }),
      );
    },
  });

  useEffect(() => {
    handleGetProjectsByProduct({ first: pageSize, product, isOnlyDisplayingPaidProject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      onLoad(false);
    } else onLoad(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');
    setTableProjects([]);
    handleGetProjectsByProduct({ first: pageSize, product, isOnlyDisplayingPaidProject });
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMoreProjectsByProduct({
      after: lastCursor,
      first: pageSize,
      filter,
      product,
      isOnlyDisplayingPaidProject,
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
    setTableProjects([]);
    handleGetProjectsByProduct({ first: pageSize, filter: value, product, isOnlyDisplayingPaidProject });
  };

  const onDashboardButtonClick = (id: string) => {
    void handleGetGeladaProject({ projectId: id });
  };

  const onOnlyDisplayPaidProjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked },
    } = event;

    setIsOnlyDisplayingPaidProject(isChecked);
    handleGetProjectsByProduct({ first: pageSize, filter, product, isOnlyDisplayingPaidProject: isChecked });
  };

  const PROJECTS_TABLE_COLUMNS: ReadonlyArray<Column<IMuriquiProjectsTableData>> = [
    {
      accessor: 'name',
      Cell: ({ value }) => (
        <div className="c-projects-table__projects-cell">
          <Avatar size="small" title={value} />
          {value}
        </div>
      ),
      Header: () => <div>Project</div>,
    },
    {
      accessor: 'settingsButtonData',
      Cell: ({ value: { id: projectId, isPaid } }) => (
        <div className="c-projects-table__projects-cell">
          <Button
            iconId="id_new_window_icon"
            size="xs"
            text="Dashboard"
            variant="secondary"
            onClick={() => onDashboardButtonClick(projectId)}
          ></Button>
          <div title={isPaid ? TOOLTIP_CONSTANTS.PAID : TOOLTIP_CONSTANTS.UNPAID}>
            <Icon className={`icon_project_paid ${isPaid ? ' paid' : 'unpaid'}`} id="id_payouts_icon" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Toolbar
          childrenEnd={
            <div>
              <ToggleSwitch
                id="display-paid"
                isChecked={isOnlyDisplayingPaidProject}
                labelText="Only display paid project"
                onChange={onOnlyDisplayPaidProjectChange}
              />
            </div>
          }
          childrenStart={
            <SearchFilter
              id="projects-filter"
              isDisabled={false}
              labelText="projects filter"
              name="searchProjects"
              placeholder="Project name"
              type="text"
              value={filter}
              isLabelHidden
              onChange={handleFilterChange}
              onClear={handleFilterClear}
              onSearch={handleFilterSearch}
            />
          }
        />
      </Spacing>
      <Table additionalClassNames="c-teams-table" columns={PROJECTS_TABLE_COLUMNS} data={tableProjects} hasButton />
      <Pagination
        isLoadMoreDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        totalItems={totalProjects}
        onPageChange={async () => {
          await handleFetchMoreProjectsByProduct({
            after: lastCursor,
            first: pageSize,
            filter,
            product,
            isOnlyDisplayingPaidProject,
          });
        }}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};
export { MuriquiProjectsTable };
