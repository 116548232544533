import { IGetPartnersTableDataParams, IPartnersTableData } from './PartnersTable.interfaces';

const GetPartnersTableData = () => {
  const GetTableData = ({ projects }: IGetPartnersTableDataParams): IPartnersTableData[] => {
    return projects.map(({ id, name }): IPartnersTableData => {
      return {
        name,
        id,
      };
    });
  };

  return { GetTableData };
};

export { GetPartnersTableData };
