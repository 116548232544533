import { AvatarBreadcrumbInformationBoxTemplate } from '@netfront/ui-library';

import { ReferralsTable } from '../Tables';

import { PageLayout } from '../../../PageLayout';

const PartnerManagementReferralsPage = () => {
  return (
    <div className="c-partner-management-page">
      <PageLayout meta={{ description: 'Partner referrals' }} size={'small'} title="Partner management referrals" hasPrivateLayout>
        <AvatarBreadcrumbInformationBoxTemplate
          breadcrumbItems={[]}
          message="As a partner, you have access to manage all partner-managed projects. You will receive a trailing commission for both partner and user managed projects however you will not have access to user managed dashboards."
          title="Referrals"
        />

        <ReferralsTable />
      </PageLayout>
    </div>
  );
};

export { PartnerManagementReferralsPage };
