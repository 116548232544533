import format from 'date-fns/format';

import { IGetReferralsTableDataParams, IReferralsTableData } from './ReferralsTable.interfaces';

const getReferralsTableData = ({ referrals }: IGetReferralsTableDataParams): IReferralsTableData[] => {
  return referrals.map(({ name, id, partner, createdAtUtc, customerDetail }): IReferralsTableData => {
    return {
      partnerCode: partner?.code ?? '',
      plan: {
        description: customerDetail?.subscriptions.map((subscription) => subscription.plan.name).join(',') ?? '',
      },
      partnerLink: {
        partnerId: partner?.id,
        projectId: id,
        name,
      },
      projectName: {
        id,
        name,
      },
      createdAtUtc: (createdAtUtc && format(new Date(createdAtUtc), 'dd/MM/yyyy')) ?? '',
    };
  });
};

export { getReferralsTableData };
