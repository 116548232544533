import React, { useState, useEffect } from 'react';

import { Icon, SideBar } from '@netfront/ui-library';
import cx from 'classnames';
import { useToast } from 'hooks';
import noop from 'lodash/noop';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { managementSidebarConstants } from './ManagementSidebar.constants';
import { ManagementSidebarProps } from './ManagementSidebar.interfaces';

const ManagementSidebar = ({ isSidebarOpen, onOpenSidebar, partnerCode }: ManagementSidebarProps) => {
  const { asPath } = useRouter();

  const { handleToastSuccess } = useToast();

  const { leftSidebarList } = managementSidebarConstants;

  const [path, setPath] = useState<string>('');

  const handleCopyPartnerCode = async (copiedValue: string) => {
    await navigator.clipboard.writeText(copiedValue);

    handleToastSuccess({
      message: `Partner code copied successfully`,
    });
  };

  useEffect(() => {
    if (!asPath) {
      return;
    }

    setPath(String(asPath.split('/').pop()) || '');
  }, [asPath]);

  return (
    <SideBar
      additionalClassNames={cx('partner-management__sidebar', {
        'partner-management__sidebar--open': isSidebarOpen,
      })}
      isSideBarOpen={isSidebarOpen}
      revealDirection="left"
      isGreyedOutOverlayDisabled
      onClose={noop}
      onRevealLeftSidebar={() => onOpenSidebar(!isSidebarOpen)}
    >
      <ul className="c-partner-manager-page-left-sidebar__list">
        {leftSidebarList.map(({ iconId, name }) => (
          <Link key={name} href={`/partner/${name.toLowerCase()}`}>
            <li
              className={cx('c-partner-manager-page-left-sidebar__list-item', {
                'c-partner-manager-page-left-sidebar__list-item--active': path === name.toLocaleLowerCase(),
              })}
            >
              <>
                <Icon className="c-partner-manager-page-left-sidebar__list-item__icon" id={iconId} />
                <span>{name}</span>
              </>
            </li>
          </Link>
        ))}
      </ul>
      <div className="c-partner-manager-page-left-sidebar__partner-code">
        <span>Partner code:</span>
        <button
          className="c-partner-manager-page-left-sidebar__partner-code-button"
          type="button"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleCopyPartnerCode(partnerCode)}
        >
          <span>{partnerCode}</span>
          <Icon className="c-partner-manager-page-left-sidebar__partner-code-icon" id="id_copy_icon" />
        </button>
      </div>
    </SideBar>
  );
};

export { ManagementSidebar };
