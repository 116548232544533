import { ChangeEvent, useEffect, useState } from 'react';

import { saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import { Avatar, Button, Pagination, SearchFilter, Spacing, Table, Toolbar } from '@netfront/ui-library';
import { useGetAllPartners } from 'hooks';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import { Column } from 'react-table';

import { GetPartnersTableData } from './PartnersTable.helpers';
import { IPartnersTableData, IPartnersTableProps } from './PartnersTable.interfaces';

const PartnersTable = ({ onLoad }: IPartnersTableProps) => {
  const [totalProjects, setTotalProjects] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filter, setFilter] = useState<string>('');
  const [tableProjects, setTableProjects] = useState<IPartnersTableData[]>([]);
  const [lastCursor, setLastCursor] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { GetTableData } = GetPartnersTableData();
  const { push } = useRouter();
  const onDashboardButtonClick = (id: number) => {
    saveItemWithExpiryToLocalStorage('partner', String(id), {
      currentTimeValueInMilliseconds: new Date().getTime(),
      expiryTime: {
        unit: 'hours',
        value: 6,
      },
    });
    
    setTimeout(() => {
      void push('/partner/dashboard');
    }, 400);
  };
  const { handleGetAllPartners, isLoading, handleFetchMorePartners } = useGetAllPartners({
    onCompleted(data) {
      const {
        partners: { edges, totalCount },
      } = data;
      const lastEdge = last(edges);
      setTotalProjects(totalCount ?? 0);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }
      const projects = edges.map((r) => r.node);
      setTableProjects(
        GetTableData({
          projects,
        }),
      );
    },
  });

  useEffect(() => {
    handleGetAllPartners({ first: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) {
      onLoad(false);
    } else onLoad(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFilter(value);
  };

  const handleFilterClear = () => {
    setFilter('');
    setTableProjects([]);
    handleGetAllPartners({ first: pageSize });
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    void handleFetchMorePartners({
      after: lastCursor,
      first: pageSize,
      filter,
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
    setTableProjects([]);
    handleGetAllPartners({ first: pageSize, filter: value });
  };

  const PROJECTS_TABLE_COLUMNS: ReadonlyArray<Column<IPartnersTableData>> = [
    {
      accessor: 'name',
      Cell: ({ value }) => (
        <div className="c-projects-table__projects-cell">
          <Avatar size="small" title={value} />
          {value}
        </div>
      ),
      Header: () => <div>Project</div>,
    },
    {
      accessor: 'id',
      Cell: ({ value }) => (
        <div className="c-projects-table__projects-cell">
          <Button
            iconId="id_new_window_icon"
            size="xs"
            text="Dashboard"
            variant="secondary"
            onClick={() => onDashboardButtonClick(value)}
          ></Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Spacing>
        <Toolbar
          childrenStart={
            <SearchFilter
              id="projects-filter"
              isDisabled={false}
              labelText="projects filter"
              name="searchProjects"
              placeholder="Partner name"
              type="text"
              value={filter}
              isLabelHidden
              onChange={handleFilterChange}
              onClear={handleFilterClear}
              onSearch={handleFilterSearch}
            />
          }
        />
      </Spacing>
      <Table additionalClassNames="c-teams-table" columns={PROJECTS_TABLE_COLUMNS} data={tableProjects} hasButton />
      <Pagination
        isLoadMoreDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        totalItems={totalProjects}
        onPageChange={async () => {
          await handleFetchMorePartners({
            after: lastCursor,
            first: pageSize,
            filter,
          });
        }}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};
export { PartnersTable };
