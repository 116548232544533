import { useContext } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { AvatarBreadcrumbInformationBoxTemplate, Spinner } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useSendHowlerContactForm, useToast } from 'hooks';

import { ContactForm } from '../../../Forms';
import { PageLayout } from '../../../PageLayout';

const PartnerManagementContactPage = () => {
  const { partner } = useContext(CachingEntitiesContext);

  const { getUser } = useUser();
  const loggedUser = getUser();

  const { handleToastError, handleToastSuccess } = useToast();

  const { handleSendHowlerContactForm, isLoading = false } = useSendHowlerContactForm({
    onCompleted: ({ isCompleted }) => {
      handleToastSuccess({
        message: isCompleted ? 'Your message has been sent' : 'Your message has not been sent',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  return (
    <div className="c-partner-management-page">
      <Spinner isLoading={isLoading} />
      <PageLayout meta={{ description: 'Partner support' }} size={'small'} title="Partner management support" hasPrivateLayout>
        <>
          <AvatarBreadcrumbInformationBoxTemplate
            breadcrumbItems={[]}
            message="Get in touch with support by completing the form or using help scout below"
            title="Support"
          />

          <div className="c-contact-form-container">
            <ContactForm
              isSubmitting={isLoading}
              onSend={({ message, organisationName }) => {
                if (!loggedUser) return;
                const { email, firstName, lastName } = loggedUser as unknown as { email: string; firstName: string; lastName: string };
                void handleSendHowlerContactForm({
                  email,
                  firstName,
                  lastName,
                  message,
                  placeholders: [
                    {
                      key: '[Firstname]',
                      value: firstName,
                    },
                    {
                      key: '[Lastname]',
                      value: lastName,
                    },
                    {
                      key: '[Email]',
                      value: email,
                    },
                    {
                      key: '[Organisation]',
                      value: partner?.name ?? organisationName,
                    },
                    {
                      key: '[Message]',
                      value: message,
                    },
                  ],
                  projectId: String(process.env.REACT_APP_PROJECT_ID),
                });
              }}
            />
          </div>
        </>
      </PageLayout>
    </div>
  );
};

export { PartnerManagementContactPage };
