import { Avatar, Icon, SettingsButton } from '@netfront/ui-library';
import Link from 'next/link';
import { Column } from 'react-table';

import { IProjectsTableData } from './ProjectsTable.interfaces';

const PROJECTS_TABLE_COLUMNS: ReadonlyArray<Column<IProjectsTableData>> = [
  {
    accessor: 'name',
    Cell: ({ value }) => (
      <div className="c-projects-table__projects-cell">
        <Avatar size="small" title={value} />
        {value}
      </div>
    ),
    Header: () => <div>Project</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id: projectId, onClick, organisationId, dashboardUrl } }) => (
      <div className="c-projects-table__projects-cell">
        <Link href={`${String(dashboardUrl)}/${organisationId}/${projectId}`} rel="noopener noreferrer" target="_blank">
          <Icon className="c-projects-table__new-window-icon" id="id_new_window_icon" />
        </Link>
        <SettingsButton supportiveText="Update project" onClick={() => onClick(projectId)} />
      </div>
    ),
  },
];

export { PROJECTS_TABLE_COLUMNS };
