import { ChangeEvent, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Button, ButtonIconOnly, Input, RadioButtonGroup, Spacing, Spinner, Tooltip } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useAddOrUpdatePartnerTeam, useToast } from 'hooks';
import { IDBPartnerTeam } from 'interfaces';
import noop from 'lodash.noop';

import { TeamSidebarGeneralViewProps } from './TeamSidebarGeneralView.interfaces';

const TeamSidebarGeneralView = ({ onClose, selectedTeam }: TeamSidebarGeneralViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { partner } = useContext(CachingEntitiesContext);

  const { handleToastSuccess, handleToastError } = useToast();

  const [team, setTeam] = useState<IDBPartnerTeam>(selectedTeam as IDBPartnerTeam);

  const selectedPartnerId = Number(partner?.id);

  const {
    user: {
      credential: { email },
      firstname: firstName,
      lastname: lastName,
      id,
    },
    permission,
  } = team;

  const { handleAddOrUpdatePartnerTeam, isLoading: isAddOrUpdatePartnerTeamLoading } = useAddOrUpdatePartnerTeam({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Team member updated successfully',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleUpdateTeamPermission = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setTeam(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBPartnerTeam),
    );
  };

  const handleSaveTeam = () => {
    handleAddOrUpdatePartnerTeam({
      input: {
        partnerId: selectedPartnerId,
        permission,
        userId: id,
      },
    });
  };

  return (
    <div className="c-team-sidebar-general-view">
      <Spinner isLoading={isAddOrUpdatePartnerTeamLoading ?? false} />
      <div className="c-sidebar__close-button">
        <ButtonIconOnly iconId="id_close_icon" text="Close sidebar" onClick={onClose} />
      </div>
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Input id="firstName" labelText="First name" name="firstName" type="text" value={firstName} isDisabled onChange={noop} />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="First name"
          />
        </section>
      </Spacing>
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Input id="lastName" labelText="Last name" name="lastName" type="text" value={lastName} isDisabled onChange={noop} />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Last name"
          />
        </section>
      </Spacing>
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Input id="email" labelText="Email" name="email" type="email" value={email} isDisabled onChange={noop} />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Email"
          />
        </section>
      </Spacing>
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <RadioButtonGroup
            items={[
              {
                id: 'View payouts',
                labelText: 'View payouts',
                value: 'VIEW_PAYOUTS',
              },
              {
                id: 'View referrals',
                labelText: 'View referrals',
                value: 'VIEW_REFERRALS',
              },
              {
                id: 'Manage team',
                labelText: 'Manage team',
                value: 'MANAGE_TEAM',
              },
              {
                id: 'Manage settings',
                labelText: 'Manage settings',
                value: 'MANAGE_SETTINGS',
              },
              {
                id: 'Access projects',
                labelText: 'Access projects',
                value: 'ACCESS_PROJECTS',
              },
            ]}
            name="permission"
            selectedValue={permission}
            title="Permissions"
            onChange={handleUpdateTeamPermission}
          />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Permissions"
          />
        </section>
      </Spacing>
      <div className="c-sidebar-save-button">
        <Button text="Save" type="button" variant="primary-inverse" onClick={handleSaveTeam} />
      </div>
    </div>
  );
};

export { TeamSidebarGeneralView };
