import { useEffect, useState, useContext } from 'react';

import { Dropdown, Icon, OrganisationNavigation } from '@netfront/ui-library';

import { IOrganisationMenuItems } from './OrganisationMenu.interfaces';

import { CachingEntitiesContext } from '../../context';

const OrganisationMenu = () => {
  const { clearCurrentPartner, dashboardUrl, partner } = useContext(CachingEntitiesContext);

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<IOrganisationMenuItems[]>([]);

  useEffect(() => {
    if (!(dashboardUrl && partner)) {
      return;
    }

    const navigationListItems = [
      {
        href: '/partner/team',
        name: 'Team',
        visible: true,
      },
      {
        href: '/partners',
        name: 'Projects',
        visible: true,
      },
      {
        name: 'Switch',
        onClick: clearCurrentPartner,
        visible: true,
      },
    ];

    setNavigationList(navigationListItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, partner]);

  return (
    <div className="c-organisation-menu">
      {partner && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Icon id="id_organisation_icon" />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <OrganisationNavigation
            organisation={{
              name: partner.name,
              url: '',
              dropdownNavigationItems: navigationList.filter((r) => r.visible),
            }}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
