import { ApolloQueryResult } from '@apollo/client';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_TEAM_QUERY } from './useGetTeam.graphql';
import {
  IGetTeamQueryGraphQLResponse,
  IGetTeamQueryVariables,
  IHandleFetchMoreTeamParams,
  IHandleGetTeamParams,
  IUseGetTeam,
  IUseGetTeamOptions,
} from './useGetTeam.interfaces';

const useGetTeam = (options?: IUseGetTeamOptions): IUseGetTeam => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTeamOptions);

  const [executeGetTeam, { fetchMore, loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetTeamQueryGraphQLResponse,
    IGetTeamQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partnerUser: { team: partnerTeamConnection },
        } = data;

        onCompleted({
          partnerTeamConnection,
        });
      },
      onError,
    },
    query: query ?? GET_TEAM_QUERY,
    token,
  });

  const handleFetchMoreTeam = ({
    after,
    first,
    partnerId,
  }: IHandleFetchMoreTeamParams): Promise<ApolloQueryResult<IGetTeamQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          partnerUser: { team: newlyFetchedTeamConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedTeamConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          partnerUser: { team: previouslyFetchedTeamConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedTeamConnection;

        return {
          ...previousQueryResult,
          partnerUser: {
            ...previouslyFetchedTeamConnection,
            team: {
              ...newlyFetchedTeamConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        partnerId,
      },
    });
  };

  const handleGetTeam = ({ after, first, partnerId }: IHandleGetTeamParams) => {
    void executeGetTeam({
      variables: {
        after,
        first,
        partnerId,
      },
    });
  };

  return {
    handleFetchMoreTeam,
    handleGetTeam,
    isLoading,
  };
};

export { useGetTeam };
