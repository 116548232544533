import { useState, useContext, useEffect, useRef, ChangeEvent, FormEvent } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import {
  AvatarBreadcrumbInformationBoxTemplate,
  Button,
  Input,
  DropzoneFileUpload,
  Spacing,
  Tooltip,
  ColorPicker,
  ColorPickerPreview,
  Spinner,
} from '@netfront/ui-library';
import cx from 'classnames';
import { CachingEntitiesContext } from 'context';
import { useCreatePartnerLogoAsset, useUpdateTheme, useUpdatePartner, useToast } from 'hooks';
import { IDBPartnerAsset, IDBPartner } from 'interfaces';
import noop from 'lodash/noop';
import { ColorResult } from 'react-color';

import { PageLayout } from '../../../PageLayout';

const PartnerManagementSettingsPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastSuccess, handleToastError } = useToast();

  const uploadedImageRef = useRef<HTMLImageElement>(null);
  const { partner, updatePartner: updatePartnerCtx, partnerTheme, updatePartnerTheme } = useContext(CachingEntitiesContext);

  const [selectedColor, setSelectedColor] = useState<string>('');
  const [isColorPicker, setIsColorPicker] = useState<boolean>(false);
  const [droppedFile, setDroppedFile] = useState<File>();
  const [currentImage, setCurrentImage] = useState<IDBPartnerAsset>();
  const [isDroppedFile, setIsDroppedFile] = useState<boolean>(false);
  const [isImageUploadToAwsLoading, setIsImageUploadToAwsLoading] = useState<boolean>(false);
  const [isRemoveUploadedImage, setIsRemoveUploadedImage] = useState<boolean>(false);
  const [currentPartner, setCurrentPartner] = useState<Omit<IDBPartner, 'organisations'>>();
  // const [partnerContactDetails, setPartnerContactDetails] = useState<IPartnerContact>({
  //   country: '',
  //   email: '',
  //   phone: '',
  // });

  const { handleCreatePartnerLogoAsset: executeCreatePartnerLogoAsset, isLoading: isCreatePartnerLogoAssetLoading = false } =
    useCreatePartnerLogoAsset({
      onCompleted: (data) => {
        const { presignedUrl, signedUrl, contentType, assetId, fileName, fileSizeInBytes, s3Key } = data;
        setIsImageUploadToAwsLoading(true);

        const options = {
          method: 'PUT',
          body: droppedFile,
        };

        fetch(signedUrl, options)
          .then(() => {
            setIsImageUploadToAwsLoading(false);

            setCurrentImage({
              ...currentImage,
              id: String(assetId),
              fileName: droppedFile?.name,
              fileSizeInBytes: droppedFile?.size,
              contentType: droppedFile?.type,
              presignedUrl,
            } as IDBPartnerAsset);

            handleToastSuccess({ message: 'Logo updated successfully' });
            updatePartnerTheme({
              ...partnerTheme,
              presignedUrl: String(presignedUrl),
              logo: {
                contentType,
                fileName,
                fileSizeInBytes: Number(fileSizeInBytes),
                presignedUrl: String(presignedUrl),
                s3Key,
                id: assetId,
              },
            });
          })
          .catch((error) => {
            handleToastError({
              error,
            });
          });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const { handleUpdateTheme: executeUpdateTheme, isLoading: isUpdateThemeLoading } = useUpdateTheme({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Partner theme updated successfully',
      });

      updatePartnerCtx({
        ...currentPartner,
        organisations: partner?.organisations ?? [],
        logo: currentImage,
        brandAccentPrimary: selectedColor,
      } as IDBPartner);

      updatePartnerTheme({
        ...partnerTheme,
        brandAccentPrimary: selectedColor,
        presignedUrl: String(currentImage?.presignedUrl),
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleUpdatePartner: executeUpdatePartner, isLoading: isUpdatePartnerLoading } = useUpdatePartner({
    onCompleted: () => {
      updatePartnerCtx({
        ...currentPartner,
        organisations: partner?.organisations ?? [],
        logo: currentImage,
        brandAccentPrimary: selectedColor,
      } as IDBPartner);

      handleToastSuccess({
        message: 'Partner updated successfully',
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleChangeBusinessDetails = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentPartner({
      ...currentPartner,
      [name]: value,
    } as IDBPartner);
  };

  const handleColorChange = (updatedColor: ColorResult) => {
    const { r, g, b, a = 0 } = updatedColor.rgb;
    const updatedColorRgba = `rgba(${r}, ${g}, ${b}, ${a})`;

    setSelectedColor(updatedColorRgba);
  };

  const handleDropFile = (acceptedFile: File[]) => {
    if (!partner) return;

    const [file] = acceptedFile;

    const { id } = partner;
    const { name, size, type } = file;

    setDroppedFile(file);
    setIsRemoveUploadedImage(!isRemoveUploadedImage);
    setIsDroppedFile(true);

    void executeCreatePartnerLogoAsset({
      input: {
        contentType: type,
        fileName: name,
        fileSizeInBytes: size,
        type: 'PARTNER_LOGO',
        partnerId: id,
      },
    });
  };

  const handleSubmitBusinessDetails = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    executeUpdatePartner({
      input: {
        address: currentPartner?.address ?? '',
        phoneNumber: currentPartner?.phoneNumber ?? '',
        email: currentPartner?.email ?? '',
        name: currentPartner?.name ?? '',
        partnerId: Number(partner?.id),
      },
    });
  };

  useEffect(() => {
    if (!isDroppedFile) {
      return;
    }

    if (uploadedImageRef.current === null) {
      return;
    }

    uploadedImageRef.current.src = URL.createObjectURL(droppedFile as Blob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDroppedFile]);

  useEffect(() => {
    if (!partner) {
      return;
    }

    const { address, code, phoneNumber, email, name, id, logo, brandAccentPrimary } = partner;

    setCurrentPartner({
      address,
      code,
      phoneNumber,
      email,
      name,
      id,
    });

    setCurrentImage(logo);
    setSelectedColor(brandAccentPrimary ?? '');
  }, [partner]);

  useEffect(() => {
    setSelectedColor(partnerTheme.brandAccentPrimary ?? '');
    setCurrentImage({
      ...currentImage,
      presignedUrl: partnerTheme.presignedUrl,
    } as IDBPartnerAsset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerTheme]);

  const handleUpdateTheme = () => {
    executeUpdateTheme({
      input: {
        partnerId: Number(partner?.id),
        brandAccentPrimary: selectedColor,
      },
    });
  };

  const isDisplayImage = isDroppedFile || currentImage?.presignedUrl;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isLoading = isUpdateThemeLoading || isCreatePartnerLogoAssetLoading || isImageUploadToAwsLoading || isUpdatePartnerLoading;

  return (
    <div className="c-partner-management-settings-page">
      <Spinner isLoading={isLoading ?? false} />
      <PageLayout meta={{ description: 'Partner settings' }} size={'small'} title="Partner management settings" hasPrivateLayout>
        <AvatarBreadcrumbInformationBoxTemplate
          breadcrumbItems={[]}
          message="Manage your business and payout information"
          title="Settings"
        />

        <form className="c-settings-page__form c-settings-page__form--container" onSubmit={handleSubmitBusinessDetails}>
          <Spacing>
            <span className="c-settings-page__form-section-title">Business details</span>
          </Spacing>
          <Spacing>
            <div className="c-settings-page__form-row">
              <div className="c-settings-page__form-field">
                <Spacing>
                  <Input
                    id="business-name"
                    labelText="Business name"
                    name="name"
                    type="text"
                    value={currentPartner?.name}
                    isRequired
                    onChange={handleChangeBusinessDetails}
                  />
                </Spacing>
              </div>

              <div className="c-settings-page__form-field">
                <Spacing>
                  <Input
                    id="email"
                    labelText="Email"
                    name="email"
                    type="email"
                    value={currentPartner?.email}
                    isRequired
                    onChange={handleChangeBusinessDetails}
                  />
                </Spacing>
              </div>
            </div>
          </Spacing>

          <Spacing>
            <div className="c-settings-page__form-row">
              <div className="c-settings-page__form-field">
                <Spacing>
                  <Input
                    id="address"
                    labelText="Address"
                    name="address"
                    type="text"
                    value={currentPartner?.address}
                    isRequired
                    onChange={handleChangeBusinessDetails}
                  />
                </Spacing>
              </div>

              <div className="c-settings-page__form-field">
                <Spacing>
                  <Input
                    id="contact-number"
                    labelText="Contact number"
                    name="contactNumber"
                    type="text"
                    value={currentPartner?.phoneNumber}
                    isRequired
                    onChange={handleChangeBusinessDetails}
                  />
                </Spacing>
              </div>
            </div>
          </Spacing>

          <div className="c-settings-page__form-submit">
            <Button text="Update" type="submit" onClick={noop} />
          </div>
        </form>

        <div className="c-settings-page__form--container">
          <Spacing>
            <span className="c-settings-page__form-section-title">Branding</span>
          </Spacing>

          {!isDisplayImage ? (
            <Spacing>
              <section className="c-sidebar-section c-sidebar-section--aligned">
                <Tooltip
                  additionalClassNames="c-sidebar-section__tooltip"
                  bubbleTheme="dark"
                  iconId="id_tooltip_icon"
                  placement="left"
                  text="Upload your logo"
                />
              </section>

              <DropzoneFileUpload fileType="image" labelText="Upload partner logo" onDrop={handleDropFile} />
            </Spacing>
          ) : (
            <Spacing>
              <div className="c-settings-page__logo">
                <img ref={uploadedImageRef} alt="your image" className="c-settings-page__logo-image" src={currentImage?.presignedUrl} />
              </div>
            </Spacing>
          )}

          <div className="c-settings-page__branding-color">
            <span className="c-settings-page__branding-color-title">Configure branding color</span>
            <ColorPickerPreview selectedColor={selectedColor} onClick={() => setIsColorPicker(!isColorPicker)} />
            <ColorPicker
              additionalClassNames={cx('c-color-picker__select', {
                'c-color-picker-show': isColorPicker,
                'c-color-picker-hide': !isColorPicker,
              })}
              color={selectedColor}
              onChange={handleColorChange}
            />

            <div className="c-settings-page__form-submit">
              <Button text="Update" type="button" onClick={handleUpdateTheme} />
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export { PartnerManagementSettingsPage };
