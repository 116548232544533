import { IGetTeamsTableDataParams, ITeamsTableData } from './TeamsTable.interfaces';

const getTeamsTableData = ({ onSettingsButtonClick, teams }: IGetTeamsTableDataParams): ITeamsTableData[] => {
  return teams.map(
    ({
      permission,
      user: {
        credential: { email },
        firstname: firstName,
        lastname: lastName,
        id,
      },
    }): ITeamsTableData => {
      return {
        email,
        firstName,
        id,
        lastName,
        permission,
        settingsButtonData: {
          id,
          onClick: onSettingsButtonClick,
        },
      };
    },
  );
};

export { getTeamsTableData };
