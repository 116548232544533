import { useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { saveItemWithExpiryToLocalStorage, useCookie } from '@netfront/common-library';
import { InformationBox, Avatar, Spinner, Spacing } from '@netfront/ui-library';
import { useGetPartnersForConnectedUser, useToast } from 'hooks';
import { IDBPartner } from 'interfaces';
import { useRouter } from 'next/router';

import { PageLayout } from '../../PageLayout';

const PartnersPage = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const [partners, setPartners] = useState<IDBPartner[]>();

  const { handleGetPartnersForConnectedUser: executeGetPartnerForConnectedUser, isLoading: isGetPartnersForConnectedUserLoading = false } =
    useGetPartnersForConnectedUser({
      onCompleted: ({ partnersConnection: { edges } }) => {
        const allPartners = edges.map(({ node }) => node);

        const partnerCount = allPartners.length;
        //If the user don't have any partner, redirects to the login page
        if (partnerCount === 0) {
          void push('/login');
          return;
        }

        //If the user has only one partner, redirect straight to the partner dashboard
        if (partnerCount === 1) {
          
          const [partner] = allPartners;
          const { id } = partner;

          saveItemWithExpiryToLocalStorage('partner', String(id), {
            currentTimeValueInMilliseconds: new Date().getTime(),
            expiryTime: {
              unit: 'hours',
              value: 6,
            },
          });
          
          void push('/partner/dashboard');
          return;
        }

        setPartners(allPartners);
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const handleClickPartner = (id: number) => {
    saveItemWithExpiryToLocalStorage('partner', String(id), {
      currentTimeValueInMilliseconds: new Date().getTime(),
      expiryTime: {
        unit: 'hours',
        value: 6,
      },
    });

    void push('/partner/dashboard');
  };

  useEffect(() => {
    void executeGetPartnerForConnectedUser({
      first: 100,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-partners-page">
      <PageLayout hasPartnerSideBar={false} meta={{ description: 'Select partners' }} title="Partners" hasPrivateLayout>
        <Spacing size="3x-large">
          <header className="c-partners-page__header">
            <Avatar title="Partners" />
            <span className="c-partners-page__header-text">Partners</span>
          </header>
        </Spacing>

        {isGetPartnersForConnectedUserLoading ? (
          <Spinner isLoading={isGetPartnersForConnectedUserLoading} />
        ) : (
          <ul className="c-button-card__list">
            {partners ? (
              partners.map((partner) => {
                const { id, name, logo } = partner;
                return (
                  <li key={id} className="c-button-card__list-item">
                    <button className="c-button-card-item" onClick={() => handleClickPartner(id)}>
                      <div className="c-card c-button-card c-card--medium">
                        <div className="c-card__header--is-title-only">
                          <Avatar image={logo?.presignedUrl} title={name} />
                        </div>
                        <span className="c-button-card__text">{name}</span>
                      </div>
                    </button>
                  </li>
                );
              })
            ) : (
              <InformationBox additionalClassNames="c-partners__information" iconId="id_warning_icon">
                <div className="c-partners__information-content">
                  <span className="c-referrals__information-text">You currently do not have any partners</span>
                </div>
              </InformationBox>
            )}
          </ul>
        )}
      </PageLayout>
    </div>
  );
};

export { PartnersPage };
