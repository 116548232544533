import { ChangeEvent, useState, useContext, FormEvent, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { Button, ButtonIconOnly, Input, RadioButtonGroup, Spacing, Spinner, Tooltip } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useGetProduct, useInvitePartnerUser, useToast } from 'hooks';
import { IDBPartnerUserInvitation } from 'interfaces';

import { AddTeamSidebarViewProps } from './AddTeamSidebarView.interfaces';

const AddTeamSidebarView = ({ onClose }: AddTeamSidebarViewProps) => {
  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { partner } = useContext(CachingEntitiesContext);

  const { handleToastSuccess, handleToastError } = useToast();
  const { getProduct } = useGetProduct();
  const product = getProduct();

  const [invitation, setInvitation] = useState<IDBPartnerUserInvitation>();

  const handleUpdateInvitation = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setInvitation({
      ...invitation,
      [name]: value,
    } as IDBPartnerUserInvitation);
  };

  const { handleInvitePartnerUser, isLoading: isAddOrUpdatePartnerTeamLoading } = useInvitePartnerUser({
    onCompleted: () => {
      handleToastSuccess({
        message: 'The user has been invited',
      });

      onClose();
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
    product,
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!invitation) return;
    event.preventDefault();

    handleInvitePartnerUser({
      input: invitation,
    });
  };

  useEffect(() => {
    if (!partner) return;
    setInvitation({
      firstName: '',
      invitedEmail: '',
      lastName: '',
      permission: 'NONE',
      partnerId: partner.id,
    });
  }, [partner]);

  const handleUpdateTeamPermission = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setInvitation(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBPartnerUserInvitation),
    );
  };

  return (
    <div className="c-team-sidebar-general-view">
      <Spinner isLoading={isAddOrUpdatePartnerTeamLoading ?? false} />
      <div className="c-sidebar__close-button">
        <ButtonIconOnly iconId="id_close_icon" text="Close sidebar" onClick={onClose} />
      </div>
      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned"></section>
      </Spacing>
      {invitation && (
        <form onSubmit={onSubmit}>
          <Spacing>
            <Input
              id="firstName"
              labelText="Firstname"
              name="firstName"
              type="text"
              value={invitation.firstName}
              isRequired
              onChange={handleUpdateInvitation}
            />
          </Spacing>
          <Spacing>
            <Input
              id="lastName"
              labelText="Lastname"
              name="lastName"
              type="text"
              value={invitation.lastName}
              isRequired
              onChange={handleUpdateInvitation}
            />
          </Spacing>
          <Spacing>
            <Input
              id="invitedEmail"
              labelText="Email"
              name="invitedEmail"
              type="email"
              value={invitation.invitedEmail}
              isRequired
              onChange={handleUpdateInvitation}
            />
          </Spacing>
          <Spacing size="large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <RadioButtonGroup
                items={[
                  {
                    id: 'View payouts',
                    labelText: 'View payouts',
                    value: 'VIEW_PAYOUTS',
                  },
                  {
                    id: 'View referrals',
                    labelText: 'View referrals',
                    value: 'VIEW_REFERRALS',
                  },
                  {
                    id: 'Manage team',
                    labelText: 'Manage team',
                    value: 'MANAGE_TEAM',
                  },
                  {
                    id: 'Manage settings',
                    labelText: 'Manage settings',
                    value: 'MANAGE_SETTINGS',
                  },
                  {
                    id: 'Access projects',
                    labelText: 'Access projects',
                    value: 'ACCESS_PROJECTS',
                  },
                ]}
                name="permission"
                selectedValue={invitation.permission}
                title="Permissions"
                onChange={handleUpdateTeamPermission}
              />

              <Tooltip
                additionalClassNames="c-sidebar-section__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Select the permission of the user"
              />
            </section>
          </Spacing>
          <div className="c-sidebar-save-button">
            <Button text="Save" type="submit" variant="primary-inverse" />
          </div>
        </form>
      )}
    </div>
  );
};

export { AddTeamSidebarView };
