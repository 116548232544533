import { useEffect, useState } from 'react';

import { IGetTamarinInsightsOnCompletedResponse, useGetTamarinInsights } from 'hooks';
import { DailyCount } from 'interfaces';

import { DailyCreation } from './DailyCreation/DailyCreation';
import { DailyScans } from './DailyScans/DailyScans';
import { QuickcodesInsightsProps } from './QuickcodesInsights.interfaces';

const QuickcodesInsights = ({ period }: QuickcodesInsightsProps) => {
  const [dailyScans, setDailyScans] = useState<DailyCount[]>([]);
  const [dailyCreation, setDailyCreation] = useState<DailyCount[]>([]);
  const onGetTamarinInsightsCompleted = (data: IGetTamarinInsightsOnCompletedResponse) => {
    const { dailyCreation: dc, dailyScans: ds } = data;
    setDailyCreation(dc);
    setDailyScans(ds);
  };
  const { handleGetTamarinInsights } = useGetTamarinInsights({ onCompleted: onGetTamarinInsightsCompleted });

  useEffect(() => {
    const { end, start } = period;
    handleGetTamarinInsights({
      end,
      start,
    });
  }, [period]);

  return (
    <>
      <DailyScans dailyScans={dailyScans} />
      <DailyCreation dailyCreation={dailyCreation} />
    </>
  );
};
export { QuickcodesInsights };
