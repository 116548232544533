import { Column } from 'react-table';

import { ITransactionsTableData } from './TransactionsTable.interfaces';

const TRANSACTIONS_TABLE_COLUMNS: ReadonlyArray<Column<ITransactionsTableData>> = [
  {
    accessor: 'paymentDate',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Payment date</div>,
  },
  {
    accessor: 'customer',
    Cell: ({ value }) => <>{value.name}</>,
    Header: () => <div>Name</div>,
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Description</div>,
  },
  {
    accessor: 'amount',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Amount</div>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Status</div>,
  },
];

export { TRANSACTIONS_TABLE_COLUMNS };
