export interface ChartPickerProps {
  availableCharts: AvailableChart[];
  defaultSelection: AvailableChart;
  onActiveSelectionChange?: (value: AvailableChart) => void;
}

export enum AvailableChart {
  'Doughnut',
  'Line',
  'Table',
  'Bar'
}
