import { ChangeEvent, useState, useEffect, useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset, useCookie } from '@netfront/common-library';
import { IDBGeladaProject, useCreateGeladaProject, useUpdateGeladaProject } from '@netfront/gelada-identity-library';
import { Button, ButtonIconOnly, DropzoneFileUpload, Input, Label, Spacing, Spinner, Textarea, Tooltip } from '@netfront/ui-library';
import { useToast, useCreateProjectAsset } from 'hooks';

import { dashboardSidebarGeneralViewConstants } from './DashboardSidebarGeneralView.constants';
import { DashboardSidebarGeneralViewProps } from './DashboardSidebarGeneralView.interfaces';

const DashboardSidebarGeneralView = ({ project, organisationId, onClose, onCreated, onUpdated }: DashboardSidebarGeneralViewProps) => {
  const uploadedImageRef = useRef<HTMLImageElement>(null);

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { handleToastError, handleToastSuccess } = useToast();

  const { descriptionCharacterMaxLength, maxUploadFileSize, titleCharacterMaxLength } = dashboardSidebarGeneralViewConstants;

  const [currentImage, setCurrentImage] = useState<IDBAsset>();
  const [currentProject, setCurrentProject] = useState<IDBGeladaProject | undefined>(project);
  const [droppedFile, setDroppedFile] = useState<File>();
  const [isImageUploadToAwsLoading, setIsImageUploadToAwsLoading] = useState<boolean>(false);

  const { name: projectName = '', description = '', id: projectId, backOfficeUrl = '' } = currentProject ?? {};

  const { handleCreateGeladaProject, isLoading: isCreateGeladaProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      onCreated(returnedProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleCreateProjectAsset: executeCreateProjectAsset, isLoading: isCreateProjectAssetLoading = false } = useCreateProjectAsset({
    onCompleted: ({ presignedUrl, signedUrl, id }) => {
      setIsImageUploadToAwsLoading(true);

      const options = {
        method: 'PUT',
        body: droppedFile,
      };

      fetch(signedUrl, options)
        .then(() => {
          setIsImageUploadToAwsLoading(false);

          setCurrentImage({
            ...currentImage,
            id,
            fileName: droppedFile?.name,
            fileSizeInBytes: droppedFile?.size,
            contentType: droppedFile?.type,
            presignedUrl,
          } as IDBAsset);

          handleToastSuccess({
            message: 'Project image uploaded successfully',
          });
        })
        .catch((error) => {
          handleToastError({
            error,
          });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleUpdateGeladaProject, isLoading: isUpdateGeladaProjectLoading = false } = useUpdateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      onUpdated(returnedProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleCreateProject = () => {
    void handleCreateGeladaProject({
      isCustomBuild: false,
      name: projectName,
      organisationId: organisationId,
    });
  };

  const handleDropFile = (acceptedFile: File[]) => {
    const [file] = acceptedFile;

    const { name, size, type } = file;

    executeCreateProjectAsset({
      alt: name,
      fileName: name,
      fileSizeInBytes: size,
      contentType: type,
      projectId: String(projectId),
      type: 'IMAGE',
    });

    setDroppedFile(file);
  };

  const handleRemoveUploadedImage = () => {
    setDroppedFile(undefined);
  };

  const handleUpdateProject = () => {
    void handleUpdateGeladaProject({
      name: projectName,
      projectId: String(projectId),
      description: description || '',
      url: backOfficeUrl || '',
    });
  };

  const handleUpdateProjectInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentProject(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IDBGeladaProject),
    );
  };

  useEffect(() => {
    if (!droppedFile) {
      return;
    }

    if (uploadedImageRef.current === null) {
      return;
    }

    uploadedImageRef.current.src = URL.createObjectURL(droppedFile as Blob);
  }, [droppedFile]);

  const isLoading =
    isCreateGeladaProjectLoading || isUpdateGeladaProjectLoading || isImageUploadToAwsLoading || isCreateProjectAssetLoading;

  return (
    <div className="c-dashboard-sidebar-general-view">
      <Spinner isLoading={isLoading} />

      <div className="c-sidebar__close-button">
        <ButtonIconOnly iconId="id_close_icon" text="Close sidebar" onClick={onClose} />
      </div>

      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Input
            id="name"
            labelText="Create your workspace"
            maxLength={titleCharacterMaxLength}
            name="name"
            type="text"
            value={projectName}
            onChange={handleUpdateProjectInput}
          />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Workspace name"
          />
        </section>
      </Spacing>

      {project ? (
        <>
          <Spacing size="large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <Textarea
                id="description"
                labelText="Description"
                maxLength={descriptionCharacterMaxLength}
                name="description"
                value={description}
                onChange={handleUpdateProjectInput}
              />

              <Tooltip
                additionalClassNames="c-sidebar-section__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Workspace description"
              />
            </section>
          </Spacing>

          <Spacing size="2x-large">
            <section className="c-sidebar-section c-sidebar-section--aligned">
              <Input id="url" labelText="Url" name="backOfficeUrl" type="text" value={backOfficeUrl} onChange={handleUpdateProjectInput} />

              <Tooltip
                additionalClassNames="c-sidebar-section__tooltip"
                bubbleTheme="dark"
                iconId="id_tooltip_icon"
                placement="left"
                text="Workspace url"
              />
            </section>
          </Spacing>

          {!droppedFile ? (
            <>
              <Spacing size="2x-small">
                <section className="c-sidebar-section c-sidebar-section--aligned">
                  <Label forId="copy" labelText="Avatar (optional)" />

                  <Tooltip
                    additionalClassNames="c-sidebar-section__tooltip"
                    bubbleTheme="dark"
                    iconId="id_tooltip_icon"
                    placement="left"
                    text="Upload your logo"
                  />
                </section>
              </Spacing>

              <DropzoneFileUpload fileType="image" labelText="" maxFileSize={maxUploadFileSize} onDrop={handleDropFile} />
            </>
          ) : (
            <div className="c-dashboard-sidebar-general-view__logo">
              <img ref={uploadedImageRef} alt="your image" className="c-dashboard-sidebar-general-view__logo-asset" src="/#" />

              <div className="c-remove-asset-button">
                <ButtonIconOnly iconId="id_close_icon" text="" onClick={handleRemoveUploadedImage} />
              </div>
            </div>
          )}
        </>
      ) : null}

      <div className="c-sidebar-save-button">
        <Button text="Save" type="button" variant="primary-inverse" onClick={project ? handleUpdateProject : handleCreateProject} />
      </div>
    </div>
  );
};

export { DashboardSidebarGeneralView };
