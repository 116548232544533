import { gql } from '@apollo/client';

const GET_BY_PRODUCT = gql`
  query getByProduct($product: EProduct!, $after: String, $first: Int, $filter: String, $isOnlyDisplayingPaidProject: Boolean) {
    projects {
      getByProduct(
        after: $after
        first: $first
        filter: $filter
        product: $product
        hasAtLeastOnePaidSubscription: $isOnlyDisplayingPaidProject
      ) {
        totalCount
        edges {
          cursor
          node {
            id: guid
            name
            customerDetail(product: $product) {
              subscriptions {
                plan {
                  isFree
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_BY_PRODUCT };
