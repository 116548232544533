import { useContext, useState } from 'react';

import { AppHeader, Footer, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import { UserAccountMenu, OrganisationMenu, ManagementSidebar } from 'components';
import { CachingEntitiesContext } from 'context';
import Head from 'next/head';

import { IMeta, PageLayoutProps } from './PageLayout.types';

const PageLayout: React.FC<PageLayoutProps> = ({
  additionalClassNames,
  children,
  hasPrivateLayout,
  hasPartnerSideBar = true,
  isPreloaderVisible = false,
  meta,
  size = '',
  title,
  isDisplayLayoutTemplate = true,
}) => {
  const { description = 'Description' } = meta ?? ({} as IMeta);

  const { partner } = useContext(CachingEntitiesContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
      </Head>

      {isDisplayLayoutTemplate ? (
        <PageLayoutTemplate
          additionalClassNames={cx('c-page-layout-template', additionalClassNames)}
          footer={<Footer projectName="Netfront" />}
          header={
            <AppHeader
              logo={{
                href: '/partner/dashboard',
                imageSrc: partner?.logo?.presignedUrl,
              }}
            >
              {hasPrivateLayout && (
                <>
                  <OrganisationMenu />
                  <UserAccountMenu />
                </>
              )}
            </AppHeader>
          }
        >
          {partner && hasPartnerSideBar && (
            <ManagementSidebar
              isSidebarOpen={isSidebarOpen}
              partnerCode={partner.code}
              onOpenSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
            />
          )}

          <Spinner isLoading={isPreloaderVisible} />

          <main className="c-page-layout-template__main">
            {hasPrivateLayout ? (
              <div
                className={cx({
                  'c-container c-container--transition': true,
                  [`c-container--${size}`]: size,
                })}
              >
                {children}
              </div>
            ) : (
              children
            )}
          </main>
        </PageLayoutTemplate>
      ) : (
        children
      )}
    </>
  );
};

export { PageLayout };
