import { useState } from 'react';

import { Card } from '@netfront/ui-library';
import { getSum, mergeDailyCounts } from 'utils';

import { AvailableChart, BarChart, ChartPicker, LineChart } from 'components/Charts';
import { IBarChartDataset } from 'components/Charts/BarChart/BarChart.interfaces';
import { IChartKeyComponent, ILegend } from 'components/Charts/Charts.interfaces';
import { ILineChartDataset } from 'components/Charts/LineChart/LineChart.interfaces';

import { TotalProjectsProps } from './TotalProjects.interfaces';

const TotalProjects = ({ comparisonPeriodDailyCount, selectedPeriodDailyCount }: TotalProjectsProps) => {
  const LABEL = 'Total projects';

  const [activeChart, setActiveChart] = useState<AvailableChart>(AvailableChart.Line);

  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const THIS_PERIOD_COLOR = '#972868';
  const COMPARISON_COLOR = '#4BC2CA';
  const merged = mergeDailyCounts(selectedPeriodDailyCount, comparisonPeriodDailyCount);
  const barData: IBarChartDataset[] = [
    {
      backgroundColor: [THIS_PERIOD_COLOR],
      label: 'Values',
      data: merged.map((r) => r.current),
    },
    {
      backgroundColor: [COMPARISON_COLOR],
      label: 'Values',
      data: merged.map((r) => r.previous),
    },
  ];
  const labels = merged.map((r) => r.date.toString());

  const lineChart: ILineChartDataset[] = [
    { backgroundColor: THIS_PERIOD_COLOR, borderColor: THIS_PERIOD_COLOR, data: merged.map((r) => r.current), label: 'This period' },
    { backgroundColor: COMPARISON_COLOR, borderColor: COMPARISON_COLOR, data: merged.map((r) => r.previous), label: 'Comparison period' },
  ];

  const sum = getSum(selectedPeriodDailyCount, comparisonPeriodDailyCount);

  const legends: ILegend[] = [
    {
      color: THIS_PERIOD_COLOR,
      label: 'Current period',
    },
    {
      color: COMPARISON_COLOR,
      label: 'Comparison period',
    },
  ];

  const availableCharts: IChartKeyComponent[] = [
    { value: AvailableChart.Line, component: <LineChart datasets={lineChart} label={LABEL} labels={labels} legends={legends} /> },
    {
      value: AvailableChart.Bar,
      component: <BarChart datasets={barData} displayedValue={sum} label={LABEL} labels={labels} legends={legends} />,
    },
  ];

  return (
    <Card additionalClassNames="insight-card">
      <ChartPicker
        availableCharts={availableCharts.map((r) => r.value)}
        defaultSelection={activeChart}
        onActiveSelectionChange={onActiveChartChange}
      />
      {availableCharts.find((r) => r.value === activeChart)?.component}
    </Card>
  );
};
export { TotalProjects };
