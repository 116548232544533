import { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Avatar, Dropdown, UserNavigation } from '@netfront/ui-library';

const UserAccountMenu = () => {
  const { getUser } = useUser();
  const loggedUser = getUser();

  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setIsHydrated(true);
  }, []);
  if (!isHydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }

  return (
    <div className="c-user-account-menu">
      {loggedUser && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Avatar size="small" title={`${loggedUser.firstName} ${loggedUser.lastName}`} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation
            userNavigationListItems={[
              {
                href: String(profileUrl),
                name: 'Profile',
              },
              {
                href: logoutUrl,
                name: 'Logout',
              },
            ]}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
