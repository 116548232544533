import { ChangeEvent, useState, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { IGeladaOrganisation, useCreateGeladaOrganisation } from '@netfront/gelada-identity-library';
import { Button, ButtonIconOnly, Input, Spacing, Spinner, Tooltip } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { useToast, useLinkOrganisation } from 'hooks';

import { accountSidebarGeneralViewConstants } from './AccountSidebarGeneralView.constants';
import { AccountSidebarGeneralViewProps } from './AccountSidebarGeneralView.interfaces';

const AccountSidebarGeneralView = ({ onClose, onCreated, onLinked }: AccountSidebarGeneralViewProps) => {
  const { titleCharacterMaxLength } = accountSidebarGeneralViewConstants;

  const { getAccessTokenCookie } = useCookie();
  const token = getAccessTokenCookie();

  const { partner } = useContext(CachingEntitiesContext);

  const { handleToastError } = useToast();

  const [organisation, setOrganisation] = useState<IGeladaOrganisation | undefined>();

  const { handleCreateGeladaOrganisation, isLoading: isCreateGeladaOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation: returnedOrganisation }) => {
      onCreated(returnedOrganisation);

      handleLinkOrganisation({
        input: {
          organisationId: returnedOrganisation.id,
          partnerId: Number(partner?.id),
        },
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleLinkOrganisation, isLoading: isLinkOrganisationLoading } = useLinkOrganisation({
    onCompleted: ({ result }) => {
      onLinked(result);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleUpdateOrganisationInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setOrganisation(
      (currentState) =>
        ({
          ...currentState,
          [name]: value,
        } as IGeladaOrganisation),
    );
  };

  const handleCreateOrganisation = () => {
    void handleCreateGeladaOrganisation({
      name: String(organisation?.name),
      description: '',
      tag: '',
      url: '',
    });
  };

  const isLoading = isCreateGeladaOrganisationLoading || isLinkOrganisationLoading;

  return (
    <div className="c-dashboard-sidebar-general-view">
      {isLoading && <Spinner isLoading={isLoading} />}

      <div className="c-sidebar__close-button">
        <ButtonIconOnly iconId="id_close_icon" text="Close sidebar" onClick={onClose} />
      </div>

      <Spacing size="large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Input
            id="name"
            labelText="Create your account"
            maxLength={titleCharacterMaxLength}
            name="name"
            type="text"
            value={organisation?.name}
            onChange={handleUpdateOrganisationInput}
          />

          <Tooltip
            additionalClassNames="c-sidebar-section__tooltip"
            bubbleTheme="dark"
            iconId="id_tooltip_icon"
            placement="left"
            text="Workspace name"
          />
        </section>
      </Spacing>

      <div className="c-sidebar-save-button">
        <Button text="Save" type="button" variant="primary-inverse" onClick={handleCreateOrganisation} />
      </div>
    </div>
  );
};

export { AccountSidebarGeneralView };
