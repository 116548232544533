import { NavigationButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IPayoutsTableData } from './PayoutsTable.interfaces';

const PAYOUTS_TABLE_COLUMNS: ReadonlyArray<Column<IPayoutsTableData>> = [
  {
    accessor: 'period',
    Cell: ({ value }) => <>{value}</>,
    Header: () => <div>Period</div>,
  },
  {
    accessor: 'amount',
    Cell: ({ value }) => <>{value / 100}</>,
    Header: () => <div>Amount</div>,
  },
  {
    accessor: 'breakdownButtonData',
    Cell: ({ value: { onClick, id } }) => (
      <NavigationButton
        additionalClassNames="c-payouts-table__breakdown-button"
        direction="next"
        iconId="id_arrow_icon"
        onClick={() => onClick(id)}
      />
    ),
    Header: () => <div />,
  },
];

export { PAYOUTS_TABLE_COLUMNS };
